const glowThemeKey = "glow-theme.v20230503";

export const SetGlowTheme = (theme: "light" | "dark") => {
    localStorage.setItem(glowThemeKey, theme);
};

export const GetGlowTheme = (): "light" | "dark" => {
    var theme = localStorage.getItem(glowThemeKey);

    // always light for now
    // if(theme === "dark" || theme === "light") {
    //     return theme;
    // }
    return "light";
}
