import { useContext, useEffect } from "react";
import { ErrorBox } from "../components/ErrorBox";
import { GlowChrome } from "../components/GlowChrome";
import { ExpandGlowJwt, GlowJwtContext } from "../../jwt/GlowJWTContext";
import { GlowColumnBox } from "../components/GlowBox";
import { Box, useTheme } from "@mui/material";

const SubstackFeed: React.FC = () => {
  const theme = useTheme()  
  useEffect(() => {
    // Configure the widget settings
    (window as any).SubstackFeedWidget = {
      substackUrl: "blog.glow.rsvp",
      posts: 12,
      colors: {
        primary: theme.palette.text.primary,
        secondary: theme.palette.text.secondary,
        background:"#FFFFFF00"
      }
    };

    // Dynamically load the Substack script
    const script = document.createElement("script");
    script.src = "https://substackapi.com/embeds/feed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      // Cleanup the script to prevent duplicates or memory leaks
      document.body.removeChild(script);
    };
  }, [theme]); // Empty dependency array means this effect runs only once

  return <Box id="substack-feed-embed" sx={{
    marginTop: "5vh",
    width: "95%",
    maxWidth: "800px",
  }} />
};

export const BlogView2: React.FC = () => {
  return <SubstackFeed />;
};
