import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  IconButton,
  Slide,
  Slider,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { GlowColumnBox } from "../components/GlowBox";
import { GlowTextField } from "../components/GlowTextField";
import { GlowPhoneField } from "../components/GlowPhoneField";
import { gql } from "../../codegen";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { GlowJwtContext } from "../../jwt/GlowJWTContext";
import { useNavigate } from "react-router-dom";
import { ConstCreateAccountOrSignInSnack } from "../components/CreateAccountOrSignInSnack";
import { PrincipalType } from "../../codegen/graphql";
import InfoIcon from "@mui/icons-material/Info";
import { InfoComponent } from "../components/InfoComponent";
import { GlowChrome } from "../components/GlowChrome";
import { ConfirmationDialog } from "../components/ConfirmationDialog";

export const PROFILE_QUERY = gql(`
query profileQueryV3
{
	getUserProfile{
		id,
		name,
		sms,
    notificationSettings
    {
      sms_myEvents
    }
	}
}`);

const PROFILE_PATCH = gql(` 
mutation patchProfileV3($patch: UserProfilePatch!){
        patchUserProfile(patch:$patch)
}`);

const DELETE_ACCOUNT = gql(`
mutation deleteAccount{
  deleteAccount
}`);

export const ProfileView = () => {
  const [name, setName] = useState<string>("");
  const client = useApolloClient();
  const nav = useNavigate();

  const context = useContext(GlowJwtContext);

  const { data, loading, error, refetch } = useQuery(PROFILE_QUERY, {
    onCompleted: (data) => {
      setName(data.getUserProfile.name);
    },
  });

  const [patchProfile, patchProfileState] = useMutation(PROFILE_PATCH, {});
  const [open, setOpen] = useState(false);

  const [deleteAccount, deleteAccountState] = useMutation(DELETE_ACCOUNT, {
    onCompleted: () => {
      context.setJwt(undefined)
      nav("/");
    },
  });
  const [deleteAccountDialog, setDeleteAccountDialog] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpen(true), 500);
  });

  const disabled = Boolean(
    context.jwt?.principalType !== PrincipalType.Verified || loading || deleteAccountState.loading
  );
  return (
    <>
      <ConfirmationDialog
        open={deleteAccountDialog}
        header="Delete Account"
        message="Are you sure you want to delete your account? This cannot be undone."
        bias="cancel"
        okText="Delete Account"
        
        onCancel={() => setDeleteAccountDialog(false)}
        onClose={() => setDeleteAccountDialog(false)}
        onConfirm={() => {
          deleteAccount()
        }}
       />
        
      <ConstCreateAccountOrSignInSnack
        open={disabled && open && !loading}
        onClose={() => {}}
        body="Login to access your profile!"
      />

      <Slide in direction="up" mountOnEnter unmountOnExit>
        <Box>
          <GlowChrome navigationFAB={true} animation={true} />

          <GlowColumnBox
            sx={{
              justifyContent: "left",
              pointerEvents: disabled ? "none" : undefined,
              opacity: disabled ? 0.5 : 1,
              minHeight: "75vh",
            }}
          >
            <Typography variant="h5">Profile</Typography>
            <GlowColumnBox sx={{ alignItems: "left" }}>
              <GlowTextField
                label="Name"
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                disabled={patchProfileState.loading || loading}
                onBlur={() => {
                  patchProfile({
                    variables: {
                      patch: {
                        name: name,
                      },
                    },
                    onError: (error) => {
                      refetch();
                    },
                    onCompleted: (data) => {
                      client.resetStore();
                    },
                  });
                }}
              ></GlowTextField>
              <GlowPhoneField
                label="phone"
                disabled
                value={data?.getUserProfile.sms}
              ></GlowPhoneField>
              {/* <FormControlLabel
                label="Dark Mode"
                control={
                  <Switch
                    checked={context.themeMode == "dark"}
                    onChange={(v) =>
                      context.setThemeMode(v.target.checked ? "dark" : "light")
                    }
                  />
                }
              /> */}
              <FormControlLabel
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>Notify me about my events</Typography>
                    <InfoComponent
                      helpBlob={
                        <>
                          <Typography>Receive text notifications:</Typography>
                          <li>After the first response to your poll</li>
                          <li>Daily at 1pm for new or updated responses</li>
                        </>
                      }
                    />
                  </Box>
                }
                control={
                  <Switch
                    disabled={loading || patchProfileState.loading}
                    checked={
                      data?.getUserProfile.notificationSettings.sms_myEvents
                    }
                    onChange={(v, e) => {
                      patchProfile({
                        variables: {
                          patch: {
                            sms_myEvents: e,
                          },
                        },
                        onCompleted: () => {
                          refetch();
                        },
                      });
                    }}
                  />
                }
              />
            </GlowColumnBox>
            {/* <Button variant="contained" onClick={() => nav(-1)}>
              Done
            </Button> */}


            <Button
            variant="text"
            onClick={() => setDeleteAccountDialog(true)}
            sx={{
              color: t=>t.palette.error.main,
              marginTop:"auto",
            }}
            >
              Delete Account
            </Button>
          </GlowColumnBox>
        </Box>
      </Slide>
    </>
  );
};

export default ProfileView;
