import { Paper, PaperProps,  } from "@mui/material";

export const GlowSecondaryPaper: React.FC<PaperProps> = (props) => {
  return (
    <Paper
      variant="elevation"
      {...props}
      sx={{
        backgroundColor: (theme) => theme.palette.extraPaperColor.main,
        borderRadius: "28px",
        boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.25)",
        ...props.sx,
      }}
    />
  );
};
