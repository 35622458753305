import {
  Box,
  CssBaseline,
  Fab,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GlowFab } from "./GlowFab";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useEffect, useMemo, useState } from "react";
import { loadSlim } from "@tsparticles/slim"; 
import { loadFull } from "tsparticles";

import {
  type Container,
  type ISourceOptions,
} from "@tsparticles/engine";

export const GlowChrome: React.FC<{
  navigationFAB: boolean;
  animation: boolean;
  shareAction?: () => void;
}> = (props) => {
  const isMobileSize = useMediaQuery("(max-width:767px)");
  const showFab = props.navigationFAB;

  const containerSXMobile: SxProps<Theme> = {
    position: "fixed",
    bottom: (t) => t.spacing(2),
    right: (t) => t.spacing(2),
    zIndex: 100000,
  };

  const containerSXDesktop: SxProps<Theme> = {
    position: "fixed",
    width: "100%",
    bottom: (t) => t.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    zIndex: 100000,
  };

  const buttonGroupSXMobile: SxProps<Theme> = {
    display: "flex",
    flexDirection: "row",
    gap: (t) => t.spacing(2),
  };

  const buttonGroupSXDesktop: SxProps<Theme> = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    width: "500px",

    gap: (t) => t.spacing(2),
  };

  const containerSX = isMobileSize ? containerSXMobile : containerSXDesktop;
  const buttonGroupSX = isMobileSize
    ? buttonGroupSXMobile
    : buttonGroupSXDesktop;

  return (
    <>
      <CssBaseline />

      <Box sx={containerSX}>
        <Box sx={buttonGroupSX}>
          {props.shareAction && (
            <Fab
              variant="extended"
              size="large"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: (t) => t.spacing(2),
                zIndex: 100000,
              }}
              onClick={() => props.shareAction!()}
            >
              <GroupAddRoundedIcon sx={{}} />

              <Typography>Invite Guests</Typography>
            </Fab>
          )}
          {showFab && <GlowFab />}
        </Box>

      </Box>

      <Box sx={{
        position: "fixed",
        zIndex:-10000
      }}>
      {props.animation && GlowParticles()}

      </Box>
    </>
  );
};

function GlowParticles() {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      await loadFull(engine);
      // await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(container);
  };

  const theme = useTheme();
  const options = theme.palette.particles;

  if (init && options !== undefined && options != "") {
    const optionsJson = JSON.parse(options);
    return (
      <Particles
        className="chromeParticles"
        id="tsparticles"

        
        particlesLoaded={particlesLoaded}
        options={optionsJson}
      />
    );
  }

  return <></>;
}
