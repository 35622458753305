import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { GlowColumnBox, GlowRowBox } from "../components/GlowBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ThemeExtensions } from "../themes/ThemeExtensions";
import { ConstructionIcon, HostIcon } from "../components/ResponseIcons";
import { GlowEditButton } from "../components/GlowEditButton";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import { useState } from "react";
import { gql } from "../../codegen";
import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { EventViewStatus } from "../../codegen/graphql";
import { GlowFormBox } from "../components/GlowFormBox";
import { GlowPhoneField } from "../components/GlowPhoneField";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { TopLeftBackButton } from "../components/TopLeftBackButton";
import { GlowSecondaryPaper } from "../components/GlowSecondaryPaper";
import { useUserThemes } from "../../cms/queries/getUserThemes";
import { ThemeSelector } from "./ThemeSelector";

const HOST_FEATURES_QUERY = gql(`
query getHostFeatures($eventCode: String!)
{
	getGlowEvent(eventCode: $eventCode)
	{
		status,
    showAccepts,
    showMaybes,
    showDeclines,
    cohosts,
    creator,
    hostV2
    {
      sms,
      name,
      self
    },
    attendanceCap,
    additionalGuestsAllowed,
    themeId
	}
}`);

const CANCEL_EVENT_MUTATION = gql(`
mutation cancelHostFeatures($eventCode:String!, $cancel:Boolean!)
{
	patchEvent(eventCode: $eventCode, patch: {
		cancel:$cancel
	})
	{
		eventCode
		status
	}
}`);

const SET_RSVP_VISIBILITY_MUTATION = gql(`
mutation setRSVPVisibility($eventCode:String!, $showAccepts:Boolean!, $showMaybes:Boolean!, $showDeclines:Boolean!)
{
  patchEvent(eventCode: $eventCode, patch: {
    showAccepts:$showAccepts,
    showMaybes:$showMaybes,
    showDeclines:$showDeclines
  })
  {
    eventCode
  }
}
`);

const SET_COHOSTS_MUTATION = gql(`
mutation setCohosts($eventCode:String!, $cohosts:[String!]!)
{
  patchEvent(eventCode: $eventCode, patch: {
    cohosts:$cohosts
  })
  {
    eventCode
  }
}`);

const HOST_PATCH = gql(`
mutation hostPatch($eventCode:String!, $patch:GlowEventPatch!)
{
  patchEvent(eventCode: $eventCode, patch: $patch)
  {
    eventCode
  }
}
`);

const CHECK_COHOST = gql(`
query getProfileForSMS($smsNumber:String!)
{
	getProfileForSMS(smsNumber:$smsNumber){
		id
		name
		sms
	}
}
`);

interface HostFeaturesProps {
  onClose: () => void;
  eventCode: string;
}

export const FeatureHeader: React.FC<TypographyProps> = (props) => {
  const theme = useTheme();
  const themExt = ThemeExtensions(theme);
  return <Typography color={themExt.text.accent} {...props} />;
};

interface FeatureRowProps {
  header: string;
  details?: string;
  indent?: boolean;
  comingSoon?: boolean;
  disabled?: boolean;
  control: React.ReactNode;
}

export const FeatureRow: React.FC<FeatureRowProps> = (props) => {
  return (
    <Tooltip title={props.comingSoon ? "Coming Soon!" : ""}>
      <Box
        sx={{
          width: "100%",
          opacity: props.comingSoon || props.disabled ? 0.5 : 1,
        }}
      >
        <Grid container>
          <Grid item xs={props.indent ? 1 : 0}></Grid>
          <Grid item xs={props.indent ? 8 : 9}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
              }}
            >
              <FeatureHeader variant={props.indent ? "body2" : "body1"}>
                {props.header}
              </FeatureHeader>
              {props.details && (
                <Typography variant={props.indent ? "caption" : "body2"}>
                  {props.details}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                pointerEvents:
                  props.comingSoon || props.disabled ? "none" : "auto",
              }}
            >
              {props.control}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Tooltip>
  );
};

export const HostFeatures: React.FC<HostFeaturesProps> = (props) => {
  // Host Features Query & Refetch
  const { data, loading, refetch } = useQuery(HOST_FEATURES_QUERY, {
    variables: {
      eventCode: props.eventCode,
    },
    onCompleted: (data) => {
      setTempMaxGuests(data.getGlowEvent.attendanceCap ?? null);
    },
  });

  // Edit Mutations
  const [cancelEvent, cancelEventState] = useMutation(CANCEL_EVENT_MUTATION);
  const [setRSVPVisibility, setRSVPVisibilityState] = useMutation(
    SET_RSVP_VISIBILITY_MUTATION
  );
  const [hostPatch, hostPatchState] = useMutation(HOST_PATCH);

  // Cancel Stuff
  const [cancelOpen, setCancelOpen] = useState(false);

  // Control States
  const [tempMaxGuests, setTempMaxGuests] = useState<number | null>(null);

  // Add Cohost Stuff
  const [addCohostOpen, setAddCohostOpen] = useState(false);
  const [setCohosts, setCohostsState] = useMutation(SET_COHOSTS_MUTATION);
  const {
    data: checkHostData,
    loading: checkHostLoading,
    refetch: checkHostRefetch,
    error: checkHostError,
  } = useQuery(CHECK_COHOST);
  const [cohostNumber, setCohostNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <GlowColumnBox>
      <TopLeftBackButton onClick={props.onClose} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <HostIcon />
        <FeatureHeader variant="h5">Host Features</FeatureHeader>
      </Box>
      <ConfirmationDialog
        open={cancelOpen}
        onCancel={() => setCancelOpen(false)}
        onConfirm={() => {
          cancelEvent({
            variables: {
              eventCode: props.eventCode,
              cancel: true,
            },
            onCompleted: () => {
              refetch();
            },
          });
          setCancelOpen(false);
        }}
        bias="cancel"
        message="Are you sure you want to cancel this event?"
        cancelText="Nevermind"
        okText="Cancel It"
      />

      {data?.getGlowEvent.status != EventViewStatus.Cancelled && (
        <FeatureRow
          header="Cancel"
          details="Cancel this event"
          control={
            <Button
              disabled={cancelEventState.loading}
              variant="text"
              onClick={() => {
                setCancelOpen(true);
              }}
            >
              Cancel
            </Button>
          }
        />
      )}

      {data?.getGlowEvent.status === EventViewStatus.Cancelled && (
        <FeatureRow
          header="Uncancel"
          details="This event is cancelled. Uncancel it?"
          control={
            <Button
              disabled={cancelEventState.loading}
              variant="text"
              onClick={() => {
                cancelEvent({
                  variables: {
                    eventCode: props.eventCode,
                    cancel: false,
                  },
                  onCompleted: () => {
                    refetch();
                  },
                });
              }}
            >
              Uncancel
            </Button>
          }
        />
      )}

      <Dialog
        open={addCohostOpen}
        onClose={() => {
          setAddCohostOpen(false);
        }}
      >
        <GlowFormBox
          onSubmit={(e) => {
            e.preventDefault();
            const phoneNumber = cohostNumber.replace(" ", "");

            const hosts = [...(data?.getGlowEvent.cohosts ?? [])];
            hosts.push(phoneNumber);
            setCohosts({
              variables: {
                eventCode: props.eventCode,
                cohosts: hosts,
              },
              onCompleted: () => {
                refetch();
                setAddCohostOpen(false);
              },
              onError: (e) => {
                // setAddCohostOpen(false);
                setErrorMessage("Failed to add cohost, confirm phone number");
              },
            });
          }}
        >
          <Typography variant="h6">Add Co-host</Typography>
          <Typography>
            Co-hosts have access to Host Features for the event
          </Typography>

          {errorMessage && (
            <Typography
              variant="caption"
              color={(theme) => {
                return theme.palette.error.main;
              }}
            >
              {errorMessage}
            </Typography>
          )}
          <GlowPhoneField
            required
            autoFocus
            value={cohostNumber}
            onChange={(x) => {
              setCohostNumber(x);
              const number = x.replaceAll(" ", "");
              checkHostRefetch({
                smsNumber: number,
              });
            }}
            label="Cohost SMS Number"
          ></GlowPhoneField>
          {/* <Typography variant="caption">
            {checkHostLoading && "Checking..."}
            {checkHostError &&
              cohostNumber.replaceAll(" ", "").length == 12 &&
              "Cohost does not have an account"}
            {checkHostError &&
              cohostNumber.replaceAll(" ", "").length != 12 &&
              "Please Check Number"}
            {checkHostData && !checkHostError && cohostNumber.replaceAll(" ","").length == 12 && "Cohost has an account"}
          </Typography> */}
          <Button variant="contained" type="submit">
            Save
          </Button>
        </GlowFormBox>
      </Dialog>
      <FeatureRow
        header="Add a Co-Host"
        disabled={!data?.getGlowEvent.creator ?? true}
        details="Co-Hosts can edit the event and see who can't make it (Only Hosts can Add Co-Hosts)"
        control={
          <Button
            onClick={() => {
              setAddCohostOpen(true);
              setCohostNumber("");
            }}
          >
            <AddRoundedIcon fontSize="large" />
          </Button>
        }
      />
      {data?.getGlowEvent?.hostV2
        ?.filter((x) => !x.self)
        .map((cohost, ndx) => {
          return (
            <FeatureRow
              indent
              key={ndx}
              header={cohost.name ?? cohost.sms}
              details={cohost.name ? cohost.sms : undefined}
              disabled={!data?.getGlowEvent.creator ?? true}
              control={
                <Button
                  size="small"
                  onClick={() => {
                    var hosts = [...(data?.getGlowEvent.cohosts ?? [])];
                    hosts = hosts.filter((x) => x != cohost.sms);

                    setCohosts({
                      variables: {
                        eventCode: props.eventCode,
                        cohosts: hosts,
                      },
                      onCompleted: () => {
                        refetch();
                      },
                    });
                  }}
                >
                  <ClearRoundedIcon fontSize="small" />
                </Button>
              }
            />
          );
        })}

      <FeatureRow
        header="Guest List"
        details="Show guests who is coming and who's not (Hosts & co-hosts can always see the entire guest list)"
        control={<></>}
      />
      <FeatureRow
        indent
        header={`Show "Yes" RSVPs`}
        control={
          <Switch
            checked={data?.getGlowEvent.showAccepts ?? false}
            disabled={
              loading || !data?.getGlowEvent || setRSVPVisibilityState.loading
            }
            onChange={(x) => {
              setRSVPVisibility({
                variables: {
                  eventCode: props.eventCode,
                  showAccepts: x.target.checked,
                  showMaybes: data!.getGlowEvent!.showMaybes,
                  showDeclines: data!.getGlowEvent!.showDeclines,
                },
                onCompleted: () => {
                  refetch();
                },
              });
            }}
            size="small"
          />
        }
      />
      <FeatureRow
        indent
        header={`Show "Maybe" RSVPs`}
        control={
          <Switch
            checked={data?.getGlowEvent.showMaybes ?? false}
            disabled={
              loading || !data?.getGlowEvent || setRSVPVisibilityState.loading
            }
            onChange={(x) => {
              setRSVPVisibility({
                variables: {
                  eventCode: props.eventCode,
                  showAccepts: data!.getGlowEvent!.showAccepts,
                  showMaybes: x.target.checked,
                  showDeclines: data!.getGlowEvent!.showDeclines,
                },
                onCompleted: () => {
                  refetch();
                },
              });
            }}
            size="small"
          />
        }
      />
      <FeatureRow
        indent
        header={`Show "No" RSVPs`}
        control={
          <Switch
            checked={data?.getGlowEvent.showDeclines ?? false}
            disabled={
              loading || !data?.getGlowEvent || setRSVPVisibilityState.loading
            }
            onChange={(x) => {
              setRSVPVisibility({
                variables: {
                  eventCode: props.eventCode,
                  showAccepts: data!.getGlowEvent!.showAccepts,
                  showMaybes: data!.getGlowEvent!.showMaybes,
                  showDeclines: x.target.checked,
                },
                onCompleted: () => {
                  refetch();
                },
              });
            }}
            size="small"
          />
        }
      />

      <FeatureRow
        header="Set Max Capacity"
        details="Limit number of Yes RSVPs"
        control={
          <TextField
            sx={{
              width: "40px",
            }}
            placeholder="None"
            value={tempMaxGuests ?? ""}
            onChange={(e) => {
              setTempMaxGuests(parseInt(e.target.value));
            }}
            onBlur={(e) => {
              const x = parseInt(e.target.value);
              if (x > 0 && x < 1000) {
                hostPatch({
                  variables: {
                    eventCode: props.eventCode,
                    patch: {
                      attendanceCap: x,
                    },
                  },
                  onCompleted: () => {
                    refetch();
                  },
                  onError: () => {
                    refetch();
                  },
                });
              } else {
                refetch();
              }
            }}
            variant="standard"
            type="number"
            size="small"
            InputProps={{
              inputProps: {
                sx: {
                  textAlign: "center",
                  color: "text.primary",
                },
                min: 0,
              },
            }}
          />
        }
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <ConstructionIcon />
        <FeatureHeader variant="h5">Coming Soon</FeatureHeader>
      </Box>

      {/* <FeatureRow
        comingSoon
        header="Set Max Capacity"
        details="Limit number of Yes RSVPs"
        control={
          <TextField
            sx={{
              width: "40px",
            }}
            placeholder="None"
            value={tempMaxGuests ?? ""}
            onChange={(e) => {
              setTempMaxGuests(parseInt(e.target.value));
            }}
            onBlur={(e) => {
              const x = parseInt(e.target.value);
              if (x > 0 && x < 1000) {
                hostPatch({
                  variables: {
                    eventCode: props.eventCode,
                    patch: {
                      attendanceCap: x,
                    },
                  },
                  onCompleted: () => {
                    refetch();
                  },
                  onError: () => {
                    refetch();
                  },
                });
              } else {
                refetch();
              }
            }}
            variant="standard"
            type="number"
            size="small"
            InputProps={{
              inputProps: {
                sx: {
                  textAlign: "center",
                  color: "text.primary",
                },
              },
            }}
          />
        }
      /> */}

      {/* <FeatureRow
        indent
        comingSoon
        header="Allow a Waitlist"
        details="Allow guest to join a waitlest; automatically update their RSVP and notify them when spots open"
        control={<Switch />}
      /> */}

      <FeatureRow comingSoon header="Auto-Reminders" control={<Switch />} />
      <FeatureRow
        comingSoon
        indent
        header="Reminders to Invited"
        control={<Switch />}
      />
      <FeatureRow
        comingSoon
        indent
        header="Reminders to Maybe"
        control={<Switch />}
      />
      <FeatureRow
        comingSoon
        indent
        header="Reminders to Yes"
        control={<Switch />}
      />

      <FeatureRow
        comingSoon
        header="Invite Expiration"
        details="Event invite expires after:"
        control={<GlowEditButton text="None" />}
      />

      <FeatureRow
        comingSoon
        header="Choose Theme"
        details="Choose a theme for your event invite page"
        control={<GlowEditButton text="None" />}
      />
    </GlowColumnBox>
  );
};

interface EditEventAdvancedBoxProps {
  refetchParent: () => void;
  setPreviewTheme: (themeId?: string) => void;
  eventCode: string;
}
export const EditEventAdvancedBox: React.FC<EditEventAdvancedBoxProps> = (
  props
) => {
  // get the user themes
  // Host Features Query & Refetch
  const {
    data,
    loading,
    refetch: refetchLocal,
  } = useQuery(HOST_FEATURES_QUERY, {
    variables: {
      eventCode: props.eventCode,
    },
    onCompleted: (data) => {
      setTempMaxGuests(data.getGlowEvent.attendanceCap ?? null);
    },
  });

  // Edit Mutations
  const [cancelEvent, cancelEventState] = useMutation(CANCEL_EVENT_MUTATION);
  const [setRSVPVisibility, setRSVPVisibilityState] = useMutation(
    SET_RSVP_VISIBILITY_MUTATION
  );
  const [hostPatch, hostPatchState] = useMutation(HOST_PATCH);

  // Cancel Stuff
  const [cancelOpen, setCancelOpen] = useState(false);

  // Control States
  const [tempMaxGuests, setTempMaxGuests] = useState<number | null>(null);

  // Add Cohost Stuff
  const [addCohostOpen, setAddCohostOpen] = useState(false);
  const [setCohosts, setCohostsState] = useMutation(SET_COHOSTS_MUTATION);
  const {
    data: checkHostData,
    loading: checkHostLoading,
    refetch: checkHostRefetch,
    error: checkHostError,
  } = useQuery(CHECK_COHOST);
  const [cohostNumber, setCohostNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // User theme
  const userThemes = useUserThemes();

  const refetch = () => {
    refetchLocal();
    props.refetchParent();
  };

  return (
    <GlowSecondaryPaper>
      <GlowColumnBox>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <HostIcon />
          <FeatureHeader variant="h5">Host Features</FeatureHeader>
        </Box>
        <ConfirmationDialog
          open={cancelOpen}
          onCancel={() => setCancelOpen(false)}
          onConfirm={() => {
            cancelEvent({
              variables: {
                eventCode: props.eventCode,
                cancel: true,
              },
              onCompleted: () => {
                refetch();
              },
            });
            setCancelOpen(false);
          }}
          bias="cancel"
          message="Are you sure you want to cancel this event?"
          cancelText="Nevermind"
          okText="Cancel It"
        />

        <FeatureRow
          header="Choose Theme"
          details="Choose a theme for your event invite page"
          control={<></>}
        />

        <ThemeSelector
          onPreview={props.setPreviewTheme}
          selectedThemeId={data?.getGlowEvent.themeId ?? undefined}
          onSelect={(t)=>{
            hostPatch({
              variables: {
                eventCode: props.eventCode,
                patch: {
                  themeId: t
                }
              },
              onCompleted: () => {
                refetch();
              },
              onError: () => {
                refetch();
              }
            })
          }}
        />

        {data?.getGlowEvent.status != EventViewStatus.Cancelled && (
          <FeatureRow
            header="Cancel"
            details="Cancel this event"
            control={
              <Button
                disabled={cancelEventState.loading}
                variant="text"
                onClick={() => {
                  setCancelOpen(true);
                }}
              >
                Cancel
              </Button>
            }
          />
        )}

        {data?.getGlowEvent.status === EventViewStatus.Cancelled && (
          <FeatureRow
            header="Uncancel"
            details="This event is cancelled. Uncancel it?"
            control={
              <Button
                disabled={cancelEventState.loading}
                variant="text"
                onClick={() => {
                  cancelEvent({
                    variables: {
                      eventCode: props.eventCode,
                      cancel: false,
                    },
                    onCompleted: () => {
                      refetch();
                    },
                  });
                }}
              >
                Uncancel
              </Button>
            }
          />
        )}

        <Dialog
          open={addCohostOpen}
          onClose={() => {
            setAddCohostOpen(false);
          }}
        >
          <GlowFormBox
            onSubmit={(e) => {
              e.preventDefault();
              const phoneNumber = cohostNumber.replace(" ", "");

              const hosts = [...(data?.getGlowEvent.cohosts ?? [])];
              hosts.push(phoneNumber);
              setCohosts({
                variables: {
                  eventCode: props.eventCode,
                  cohosts: hosts,
                },
                onCompleted: () => {
                  refetch();
                  setAddCohostOpen(false);
                },
                onError: (e) => {
                  // setAddCohostOpen(false);
                  setErrorMessage("Failed to add cohost, confirm phone number");
                },
              });
            }}
          >
            <Typography variant="h6">Add Co-host</Typography>
            <Typography>
              Co-hosts have access to Host Features for the event
            </Typography>

            {errorMessage && (
              <Typography
                variant="caption"
                color={(theme) => {
                  return theme.palette.error.main;
                }}
              >
                {errorMessage}
              </Typography>
            )}
            <GlowPhoneField
              required
              autoFocus
              value={cohostNumber}
              onChange={(x) => {
                setCohostNumber(x);
                const number = x.replaceAll(" ", "");
                checkHostRefetch({
                  smsNumber: number,
                });
              }}
              label="Cohost SMS Number"
            ></GlowPhoneField>
            {/* <Typography variant="caption">
            {checkHostLoading && "Checking..."}
            {checkHostError &&
              cohostNumber.replaceAll(" ", "").length == 12 &&
              "Cohost does not have an account"}
            {checkHostError &&
              cohostNumber.replaceAll(" ", "").length != 12 &&
              "Please Check Number"}
            {checkHostData && !checkHostError && cohostNumber.replaceAll(" ","").length == 12 && "Cohost has an account"}
          </Typography> */}
            <Button variant="contained" type="submit">
              Save
            </Button>
          </GlowFormBox>
        </Dialog>
        <FeatureRow
          header="Add a Co-Host"
          details="Co-Hosts can edit the event and see who can't make it (Only Hosts can Add Co-Hosts)"
          control={
            <Button
              onClick={() => {
                setAddCohostOpen(true);
                setCohostNumber("");
              }}
            >
              <AddRoundedIcon fontSize="large" />
            </Button>
          }
        />

        {data?.getGlowEvent?.hostV2
          ?.filter((x) => !x.self)
          .map((cohost, ndx) => {
            return (
              <FeatureRow
                indent
                key={ndx}
                header={cohost.name ?? cohost.sms}
                details={cohost.name ? cohost.sms : undefined}
                control={
                  <Button
                    size="small"
                    onClick={() => {
                      var hosts = [...(data?.getGlowEvent.cohosts ?? [])];
                      hosts = hosts.filter((x) => x != cohost.sms);

                      setCohosts({
                        variables: {
                          eventCode: props.eventCode,
                          cohosts: hosts,
                        },
                        onCompleted: () => {
                          refetch();
                        },
                      });
                    }}
                  >
                    <ClearRoundedIcon fontSize="small" />
                  </Button>
                }
              />
            );
          })}

        <FeatureRow
          header="Set Max Capacity"
          details="Limit number of Yes RSVPs"
          control={
            <TextField
              sx={{
                width: "40px",
              }}
              placeholder="None"
              value={tempMaxGuests ?? ""}
              onChange={(e) => {
                setTempMaxGuests(parseInt(e.target.value));
              }}
              onBlur={(e) => {
                const x = parseInt(e.target.value);
                if (x > 0 && x < 1000) {
                  hostPatch({
                    variables: {
                      eventCode: props.eventCode,
                      patch: {
                        attendanceCap: x,
                      },
                    },
                    onCompleted: () => {
                      refetch();
                    },
                    onError: () => {
                      refetch();
                    },
                  });
                } else {
                  refetch();
                }
              }}
              variant="standard"
              type="number"
              size="small"
              InputProps={{
                inputProps: {
                  sx: {
                    textAlign: "center",
                    color: "text.primary",
                  },
                  min: 0,
                },
              }}
            />
          }
        />

        <FeatureRow
          header={`Show "Yes" RSVPs`}
          control={
            <Switch
              checked={data?.getGlowEvent.showAccepts ?? false}
              disabled={
                loading || !data?.getGlowEvent || setRSVPVisibilityState.loading
              }
              onChange={(x) => {
                setRSVPVisibility({
                  variables: {
                    eventCode: props.eventCode,
                    showAccepts: x.target.checked,
                    showMaybes: data!.getGlowEvent!.showMaybes,
                    showDeclines: data!.getGlowEvent!.showDeclines,
                  },
                  onCompleted: () => {
                    refetch();
                  },
                });
              }}
              size="small"
            />
          }
        />
        <FeatureRow
          header={`Show "Maybe" RSVPs`}
          control={
            <Switch
              checked={data?.getGlowEvent.showMaybes ?? false}
              disabled={
                loading || !data?.getGlowEvent || setRSVPVisibilityState.loading
              }
              onChange={(x) => {
                setRSVPVisibility({
                  variables: {
                    eventCode: props.eventCode,
                    showAccepts: data!.getGlowEvent!.showAccepts,
                    showMaybes: x.target.checked,
                    showDeclines: data!.getGlowEvent!.showDeclines,
                  },
                  onCompleted: () => {
                    refetch();
                  },
                });
              }}
              size="small"
            />
          }
        />
        <FeatureRow
          header={`Show "No" RSVPs`}
          control={
            <Switch
              checked={data?.getGlowEvent.showDeclines ?? false}
              disabled={
                loading || !data?.getGlowEvent || setRSVPVisibilityState.loading
              }
              onChange={(x) => {
                setRSVPVisibility({
                  variables: {
                    eventCode: props.eventCode,
                    showAccepts: data!.getGlowEvent!.showAccepts,
                    showMaybes: data!.getGlowEvent!.showMaybes,
                    showDeclines: x.target.checked,
                  },
                  onCompleted: () => {
                    refetch();
                  },
                });
              }}
              size="small"
            />
          }
        />
      </GlowColumnBox>
    </GlowSecondaryPaper>
  );
};
