import {
  Box,
  Button,
  Checkbox,
  Grid,
  SxProps,
  Typography,
} from "@mui/material";
import { GlowColumnBox, GlowRowBox } from "../components/GlowBox";
import { gql } from "../../codegen/gql";
import { useMutation, useQuery } from "@apollo/client";
import { ReservationStatus } from "../../codegen/graphql";
import { GoingIcon, WaitlistIcon } from "../components/ResponseIcons";
import { UnsureIcon } from "../components/ResponseIcons";
import { NotGoingIcon } from "../components/ResponseIcons";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { GlowTextField } from "../components/GlowTextField";
import { useEffect, useState } from "react";
import { ThemeExtensions } from "../themes/ThemeExtensions";
import { TopLeftBackButton } from "../components/TopLeftBackButton";
import { GlowSecondaryPaper } from "../components/GlowSecondaryPaper";
import { GlowPhoneField } from "../components/GlowPhoneField";

interface GuestEditProps {
  onClose: () => void;
  eventId: string;
}

const READ_GUEST_LIST = gql(`
query readRSVPV3($eventCode: String!) {
  	getGlowEvent(eventCode: $eventCode)
	  {
      reservationDetails{
        capacity
        attendeeCount
        yesCount
        noCount
        maybeCount
        waitlistCount
        yesReservation{
          name
          numerOfGuests
          currentUser
          status
        }
        noReservation{
          name
          numerOfGuests
          currentUser
          status
        }
        maybeReservation{
          name
          numerOfGuests
          currentUser
          status
        }
        waitlistReservation{
          name
          numerOfGuests
          currentUser
          status
        }
      }
    }
    getEventRSVP(eventCode: $eventCode)
    {
        profileId
        numerOfGuests
        status
        name
        phone4
        currentUser
    }
}`);

const ADD_GUEST_AS_HOST = gql(`
mutation addGuestAsHost($eventCode: String!, $name: String!) {
    rsvpOnBehalfOfGuest(
        eventCode: $eventCode
        name: $name
        status: Yes
    )
}`);

const UPDATE_GUEST_STATUS = gql(`
mutation editGuestStatusV3($eventCode: String!, $profileId: ID!, $status: ReservationStatus!) {
    editGuestRSVP(
        eventCode: $eventCode
        profileId: $profileId
        status: $status
    )
}`);

export const GuestEdit: React.FC<GuestEditProps> = (props: GuestEditProps) => {
  const { data, loading, error, refetch } = useQuery(READ_GUEST_LIST, {
    variables: {
      eventCode: props.eventId,
    },
  });

  const [addGuestAsHost, addGuestAsHostState] = useMutation(ADD_GUEST_AS_HOST);
  const [profileOrder, setProfileOrder] = useState<string[]>([]);

  const [updateGuestStatus, updateGuestStatusState] =
    useMutation(UPDATE_GUEST_STATUS);

  const updateGuest = (profileId: string, status: ReservationStatus) => {
    updateGuestStatus({
      variables: {
        eventCode: props.eventId,
        profileId: profileId,
        status: status,
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  const [addGuestName, setAddGuestName] = useState<string>("");

  const centerStyle: SxProps = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  var headerString = "";

  const yesCount = data?.getGlowEvent?.reservationDetails?.yesCount ?? 0;
  if (yesCount > 0) {
    headerString += `${yesCount} YES`;
  }

  const waitCount = data?.getGlowEvent?.reservationDetails?.waitlistCount ?? 0;
  if (waitCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${waitCount} WAITLIST`;
  }

  const maybeCount = data?.getGlowEvent?.reservationDetails?.maybeCount ?? 0;
  if (maybeCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${maybeCount} MAYBE`;
  }

  const noCount = data?.getGlowEvent?.reservationDetails?.noCount ?? 0;
  if (noCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${noCount} NO`;
  }
  // if (props.options.showDeclines && props.noCount > 0) {
  //   if (headerString.length > 0) headerString += " | ";
  //   headerString += `${props.noCount} NO`;
  // }

  // if (props.options.showAccepts && props.capacity) {
  //   if (props.waitlistCount > 0) {
  //     if (headerString.length > 0) headerString += " | ";

  //     headerString += `${props.waitlistCount} WAITING`;
  //   } else if (props.attending < props.capacity) {
  //     if (headerString.length > 0) headerString += " | ";
  //     headerString += `${props.capacity - props.attending} SPOTS LEFT`;
  //   }
  // }

  var overage = 0;
  var remaining = 0;

  const hasMaxCapacity =
    (data?.getGlowEvent?.reservationDetails?.capacity ?? -1) > 0;

  if (data?.getGlowEvent?.reservationDetails?.capacity) {
    const capacity = data?.getGlowEvent?.reservationDetails?.capacity;

    overage = yesCount - capacity;
    remaining = capacity - yesCount;
  }

  useEffect(() => {
    console.log("GuestEdit effect");
    setProfileOrder([]);
  }, [props.eventId]);

  if (data && data.getEventRSVP.length > 0 && profileOrder.length === 0) {
    const sortedProfiles = [...data.getEventRSVP].sort((a, b) => {
      if (a.status == ReservationStatus.Yes) return -1;
      if (b.status == ReservationStatus.Yes) return 1;
      if (a.status == ReservationStatus.Waitlist) return -1;
      if (b.status == ReservationStatus.Waitlist) return 1;
      if (a.status == ReservationStatus.Maybe) return -1;
      if (b.status == ReservationStatus.Maybe) return 1;
      if (a.status == ReservationStatus.No) return -1;
      if (b.status == ReservationStatus.No) return 1;
      return 0;
    });

    console.log("sortedProfiles", sortedProfiles);
    const profileIds = sortedProfiles.map((x) => x.profileId);

    setProfileOrder(profileIds);
  }

  return (
    <GlowColumnBox>
      <TopLeftBackButton onClick={props.onClose} />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>Edit Guest Status</Typography>
        <Typography
          variant="body2"
          color={(t) => ThemeExtensions(t).text.accent}
        >
          {headerString}
        </Typography>
        {remaining > 0 && (
          <Typography
            variant="body2"
            color={(t) => ThemeExtensions(t).text.accent}
          >
            {`${remaining} SPOT${remaining > 1 ? "S" : ""} LEFT`}
          </Typography>
        )}
        {overage > 0 && (
          <Typography
            variant="body2"
            color="error"
          >{`OVER LIMIT BY ${overage}`}</Typography>
        )}
      </Box>
      <Grid container>
        <Grid
          container
          sx={{
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          {/* header */}
          <Grid item xs={7} />
          {!hasMaxCapacity && <Grid item xs={1} sx={centerStyle} />}

          <Grid item xs={1} sx={centerStyle}>
            <GoingIcon />
          </Grid>
          {hasMaxCapacity && (
            <Grid item xs={1} sx={centerStyle}>
              <WaitlistIcon />
            </Grid>
          )}
          <Grid item xs={1} sx={centerStyle}>
            <UnsureIcon />
          </Grid>
          <Grid item xs={1} sx={centerStyle}>
            <NotGoingIcon />
          </Grid>

          <Grid item xs={1} sx={centerStyle} />
        </Grid>
        {data &&
          [...data.getEventRSVP]
            .sort((a, b) => {
              const aNdx = profileOrder.indexOf(a.profileId);
              const bNdx = profileOrder.indexOf(b.profileId);

              if (aNdx < bNdx) return -1;
              if (aNdx > bNdx) return 1;
              return 0;
            })
            .map((guest, ndx) => {
              return (
                <Grid
                  container
                  key={ndx}
                  sx={{
                    borderRadius: "14px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    backgroundColor: (theme) => {
                      if (ndx % 2 == 0) {
                        return "unsert";
                      }
                      return `${theme.palette.secondary.main}20}`;
                    },
                  }}
                >
                  <Grid
                    item
                    xs={7}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography>{guest.name}</Typography>
                    {guest.numerOfGuests > 1 && (
                      <Typography
                        sx={{ marginLeft: "4px" }}
                        color={(t) =>
                          t.palette.mode == "dark"
                            ? t.palette.primary.main
                            : t.palette.primary.dark
                        }
                      >
                        {" (+" + `${guest.numerOfGuests - 1}` + ")"}
                      </Typography>
                    )}
                  </Grid>
                  {!hasMaxCapacity && <Grid item xs={1} sx={centerStyle} />}

                  <Grid item xs={1} sx={centerStyle}>
                    <Checkbox
                      checked={guest.status == ReservationStatus.Yes}
                      onClick={() => {
                        if (guest.status != ReservationStatus.Yes) {
                          updateGuest(guest.profileId, ReservationStatus.Yes);
                        }
                      }}
                    />
                  </Grid>
                  {hasMaxCapacity && (
                    <Grid item xs={1} sx={centerStyle}>
                      <Checkbox
                        checked={guest.status == ReservationStatus.Waitlist}
                        onClick={() => {
                          if (guest.status != ReservationStatus.Waitlist) {
                            updateGuest(
                              guest.profileId,
                              ReservationStatus.Waitlist
                            );
                          }
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={1} sx={centerStyle}>
                    <Checkbox
                      checked={guest.status == ReservationStatus.Maybe}
                      onClick={() => {
                        if (guest.status != ReservationStatus.Maybe) {
                          updateGuest(guest.profileId, ReservationStatus.Maybe);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sx={centerStyle}>
                    <Checkbox
                      checked={guest.status == ReservationStatus.No}
                      onClick={() => {
                        if (guest.status != ReservationStatus.No) {
                          updateGuest(guest.profileId, ReservationStatus.No);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sx={centerStyle}>
                    <Button
                      variant="text"
                      onClick={() => {
                        updateGuest(guest.profileId, ReservationStatus.Unknown);
                      }}
                    >
                      <HighlightOffIcon />
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
      </Grid>
      <GlowRowBox
        sx={{
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <GlowTextField
          disabled={addGuestAsHostState.loading}
          value={addGuestName}
          onChange={(x) => setAddGuestName(x.target.value)}
          size="small"
          label="Guest name"
        ></GlowTextField>
        <Button
          disabled={addGuestName.length < 3 || addGuestAsHostState.loading}
          variant={
            addGuestName.length < 3 || addGuestAsHostState.loading
              ? "outlined"
              : "contained"
          }
          size="small"
          sx={{ width: "120px" }}
          onClick={() => {
            addGuestAsHost({
              variables: {
                eventCode: props.eventId,
                name: addGuestName,
              },
              onCompleted: () => {
                setAddGuestName("");
                refetch();
              },
            });
          }}
        >
          Add Guest
        </Button>
      </GlowRowBox>
    </GlowColumnBox>
  );
};

export const GuestEditV2: React.FC<GuestEditProps> = (
  props: GuestEditProps
) => {
  const { data, loading, error, refetch } = useQuery(READ_GUEST_LIST, {
    variables: {
      eventCode: props.eventId,
    },
  });

  const [addGuestAsHost, addGuestAsHostState] = useMutation(ADD_GUEST_AS_HOST);
  const [profileOrder, setProfileOrder] = useState<string[]>([]);

  const [updateGuestStatus, updateGuestStatusState] =
    useMutation(UPDATE_GUEST_STATUS);

  const updateGuest = (profileId: string, status: ReservationStatus) => {
    updateGuestStatus({
      variables: {
        eventCode: props.eventId,
        profileId: profileId,
        status: status,
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  const [addGuestName, setAddGuestName] = useState<string>("");

  const centerStyle: SxProps = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  var headerString = "";

  const yesCount = data?.getGlowEvent?.reservationDetails?.yesCount ?? 0;
  if (yesCount > 0) {
    headerString += `${yesCount} YES`;
  }

  const waitCount = data?.getGlowEvent?.reservationDetails?.waitlistCount ?? 0;
  if (waitCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${waitCount} WAITLIST`;
  }

  const maybeCount = data?.getGlowEvent?.reservationDetails?.maybeCount ?? 0;
  if (maybeCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${maybeCount} MAYBE`;
  }

  const noCount = data?.getGlowEvent?.reservationDetails?.noCount ?? 0;
  if (noCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${noCount} NO`;
  }
  // if (props.options.showDeclines && props.noCount > 0) {
  //   if (headerString.length > 0) headerString += " | ";
  //   headerString += `${props.noCount} NO`;
  // }

  // if (props.options.showAccepts && props.capacity) {
  //   if (props.waitlistCount > 0) {
  //     if (headerString.length > 0) headerString += " | ";

  //     headerString += `${props.waitlistCount} WAITING`;
  //   } else if (props.attending < props.capacity) {
  //     if (headerString.length > 0) headerString += " | ";
  //     headerString += `${props.capacity - props.attending} SPOTS LEFT`;
  //   }
  // }

  var overage = 0;
  var remaining = 0;

  const hasMaxCapacity =
    (data?.getGlowEvent?.reservationDetails?.capacity ?? -1) > 0;

  if (data?.getGlowEvent?.reservationDetails?.capacity) {
    const capacity = data?.getGlowEvent?.reservationDetails?.capacity;

    overage = yesCount - capacity;
    remaining = capacity - yesCount;
  }

  useEffect(() => {
    console.log("GuestEdit effect");
    setProfileOrder([]);
  }, [props.eventId]);

  if (data && data.getEventRSVP.length > 0 && profileOrder.length === 0) {
    const sortedProfiles = [...data.getEventRSVP].sort((a, b) => {
      if (a.status == ReservationStatus.Yes) return -1;
      if (b.status == ReservationStatus.Yes) return 1;
      if (a.status == ReservationStatus.Waitlist) return -1;
      if (b.status == ReservationStatus.Waitlist) return 1;
      if (a.status == ReservationStatus.Maybe) return -1;
      if (b.status == ReservationStatus.Maybe) return 1;
      if (a.status == ReservationStatus.No) return -1;
      if (b.status == ReservationStatus.No) return 1;
      return 0;
    });

    console.log("sortedProfiles", sortedProfiles);
    const profileIds = sortedProfiles.map((x) => x.profileId);

    setProfileOrder(profileIds);
  }

  return (
    <>
      <GlowSecondaryPaper sx={{
        width: "100%",
      }}>
        <GlowColumnBox>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>Edit Guest Status</Typography>
            <Typography
              variant="body2"
              color={(t) => ThemeExtensions(t).text.accent}
            >
              {headerString}
            </Typography>
            {remaining > 0 && (
              <Typography
                variant="body2"
                color={(t) => ThemeExtensions(t).text.accent}
              >
                {`${remaining} SPOT${remaining > 1 ? "S" : ""} LEFT`}
              </Typography>
            )}
            {overage > 0 && (
              <Typography
                variant="body2"
                color="error"
              >{`OVER LIMIT BY ${overage}`}</Typography>
            )}
          </Box>
          <Grid container>
            <Grid
              container
              sx={{
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              {/* header */}
              <Grid item xs={7} />
              {!hasMaxCapacity && <Grid item xs={1} sx={centerStyle} />}

              <Grid item xs={1} sx={centerStyle}>
                <GoingIcon />
              </Grid>
              {hasMaxCapacity && (
                <Grid item xs={1} sx={centerStyle}>
                  <WaitlistIcon />
                </Grid>
              )}
              <Grid item xs={1} sx={centerStyle}>
                <UnsureIcon />
              </Grid>
              <Grid item xs={1} sx={centerStyle}>
                <NotGoingIcon />
              </Grid>

              <Grid item xs={1} sx={centerStyle} />
            </Grid>
            {data &&
              [...data.getEventRSVP]
                .sort((a, b) => {
                  const aNdx = profileOrder.indexOf(a.profileId);
                  const bNdx = profileOrder.indexOf(b.profileId);

                  if (aNdx < bNdx) return -1;
                  if (aNdx > bNdx) return 1;
                  return 0;
                })
                .map((guest, ndx) => {
                  return (
                    <Grid
                      container
                      key={ndx}
                      sx={{
                        borderRadius: "14px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        backgroundColor: (theme) => {
                          if (ndx % 2 == 0) {
                            return "unsert";
                          }
                          return `${theme.palette.secondary.main}20}`;
                        },
                      }}
                    >
                      <Grid
                        item
                        xs={7}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography>{guest.name}</Typography>
                        {guest.numerOfGuests > 1 && (
                          <Typography
                            sx={{ marginLeft: "4px" }}
                            color={(t) =>
                              t.palette.mode == "dark"
                                ? t.palette.primary.main
                                : t.palette.primary.dark
                            }
                          >
                            {" (+" + `${guest.numerOfGuests - 1}` + ")"}
                          </Typography>
                        )}
                      </Grid>
                      {!hasMaxCapacity && <Grid item xs={1} sx={centerStyle} />}

                      <Grid item xs={1} sx={centerStyle}>
                        <Checkbox
                          checked={guest.status == ReservationStatus.Yes}
                          onClick={() => {
                            if (guest.status != ReservationStatus.Yes) {
                              updateGuest(
                                guest.profileId,
                                ReservationStatus.Yes
                              );
                            }
                          }}
                        />
                      </Grid>
                      {hasMaxCapacity && (
                        <Grid item xs={1} sx={centerStyle}>
                          <Checkbox
                            checked={guest.status == ReservationStatus.Waitlist}
                            onClick={() => {
                              if (guest.status != ReservationStatus.Waitlist) {
                                updateGuest(
                                  guest.profileId,
                                  ReservationStatus.Waitlist
                                );
                              }
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={1} sx={centerStyle}>
                        <Checkbox
                          checked={guest.status == ReservationStatus.Maybe}
                          onClick={() => {
                            if (guest.status != ReservationStatus.Maybe) {
                              updateGuest(
                                guest.profileId,
                                ReservationStatus.Maybe
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} sx={centerStyle}>
                        <Checkbox
                          checked={guest.status == ReservationStatus.No}
                          onClick={() => {
                            if (guest.status != ReservationStatus.No) {
                              updateGuest(
                                guest.profileId,
                                ReservationStatus.No
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} sx={centerStyle}>
                        <Button
                          variant="text"
                          onClick={() => {
                            updateGuest(
                              guest.profileId,
                              ReservationStatus.Unknown
                            );
                          }}
                        >
                          <HighlightOffIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
          </Grid>
        </GlowColumnBox>
      </GlowSecondaryPaper>
      <GlowSecondaryPaper sx={{
        width: "100%",
      }}>
        <GlowColumnBox
          sx={{
            maxWidth: "500px",
          }}
        >
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography>Manually Add Guests</Typography>
          <Typography>(who didn't RSVP using Glow)</Typography>
          </Box>
          <GlowTextField
            disabled={addGuestAsHostState.loading}
            value={addGuestName}
            onChange={(x) => setAddGuestName(x.target.value)}
            label="Guest name"
            required
          ></GlowTextField>

          <Button
            disabled={addGuestName.length < 3 || addGuestAsHostState.loading}
            variant={
              addGuestName.length < 3 || addGuestAsHostState.loading
                ? "outlined"
                : "contained"
            }
            size="small"
            sx={{ width: "120px" }}
            onClick={() => {
              addGuestAsHost({
                variables: {
                  eventCode: props.eventId,
                  name: addGuestName,
                },
                onCompleted: () => {
                  setAddGuestName("");
                  refetch();
                },
              });
            }}
          >
            Add Guest
          </Button>
        </GlowColumnBox>
      </GlowSecondaryPaper>
    </>
  );
};
