import { Button, Typography, useTheme } from "@mui/material";
import { GlowColumnBox, GlowRowBox } from "./GlowBox";
import { GoingIcon, NotGoingIcon, UnsureIcon, WaitlistIcon } from "./ResponseIcons";
import { ReservationStatus } from "../../codegen/graphql";
import { useState } from "react";

interface RSVPButtonProps {
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  text: string;
  selected: boolean;
  disabled?: boolean;
  onClick?: () => void;
}
const RSVPButton: React.FC<RSVPButtonProps> = (props) => {
  const theme = useTheme();
  return (
    <Button
      disabled={props.disabled}
      variant="contained"
      fullWidth
      sx={{
        height: "62px",
        minWidth: "100px",
        width: "35%",
        maxWidth: "130px",
        paddingLeft: "8px",
        paddingRight: "8px",
        background: props.selected ? undefined : theme.palette.background.paper,
        border: "2px",
        borderStyle: "solid",
      }}
      onClick={props.onClick}
    >
      <GlowColumnBox
        sx={{
          gap: "0px",
          margin: "0px",
          padding: "0px",
        }}
      >
        {props.selected ? props.selectedIcon : props.icon}
        <Typography
          variant="caption"
          color={props.selected ? undefined : theme.palette.text.primary}
        >
          {props.text}
        </Typography>
      </GlowColumnBox>
    </Button>
  );
};

interface RSVPRowProps {
  selected?: ReservationStatus;
  disabled?: boolean;
  disabledText?: string;
  onChange?: (status: ReservationStatus) => void;
  showWaitlist?: boolean;
}

export const RSVPRow: React.FC<RSVPRowProps> = (props) => {
  const setStatusAndNotify = (status: ReservationStatus) => {
    if (props.onChange) {
      props.onChange(status);
    }
  };

  return (
    <>
      <GlowRowBox sx={{ margin: "0px", maxWidth: "500px", width: "100%" }}>
        {props.showWaitlist ? (
          <RSVPButton
            disabled={props.disabled}
            icon={<WaitlistIcon />}
            selectedIcon={<WaitlistIcon secondaryColor="white" />}
            text={props.selected == ReservationStatus.Waitlist?"Waitlist":"Join waitlist"}
            selected={props.selected == ReservationStatus.Waitlist}
            onClick={() => setStatusAndNotify(ReservationStatus.Waitlist)}
          />
        ) : (
          <RSVPButton
            disabled={props.disabled}
            icon={<GoingIcon />}
            selectedIcon={<GoingIcon secondaryColor="white" />}
            text="I'm in!"
            selected={props.selected == ReservationStatus.Yes}
            onClick={() => setStatusAndNotify(ReservationStatus.Yes)}
          />
        )}
        <RSVPButton
          disabled={props.disabled}
          icon={<UnsureIcon />}
          selectedIcon={<UnsureIcon secondaryColor="white" />}
          text="Not sure"
          selected={props.selected == ReservationStatus.Maybe}
          onClick={() => setStatusAndNotify(ReservationStatus.Maybe)}
        />
        <RSVPButton
          disabled={props.disabled}
          icon={<NotGoingIcon />}
          selectedIcon={<NotGoingIcon secondaryColor="white" />}
          text="Can't make it"
          selected={props.selected == ReservationStatus.No}
          onClick={() => setStatusAndNotify(ReservationStatus.No)}
        />
      </GlowRowBox>
      {props.disabledText && (
        <Typography variant="caption" color="warning">
          {props.disabledText}
        </Typography>
      )}
    </>
  );
};
