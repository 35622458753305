import { Box, Grid } from "@mui/material";
import { useUserThemes } from "../../cms/queries/getUserThemes";

export const ThemeIcon: React.FC<{
  selected: boolean;
  bgIcon: string;
  primaryColor: string;
  secondaryColor: string;
  extraPaperColor: string;
  onHover: () => void;
  onHoverOut: () => void;
  onSelect: () => void;
}> = (props) => {
  return (
    <>
      <Box
        onClick={props.onSelect}
        onMouseEnter={props.onHover}
        onMouseLeave={props.onHoverOut}
        sx={{
          width: "40px",
          height: "40px",
          borderRadius: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${props.bgIcon})`,
          backgroundSize: "cover",
          position: "relative",
          ":hover": {
            transform: "scale(1.1)",
            transition: "transform 0.25s",
            boxShadow: `0px 0px 10px 5px ${props.primaryColor}`,
          },
        }}
      >
        <Box
          sx={{
            width: "10px",
            height: "10px",
            borderRadius: "100px",
            backgroundColor: props.secondaryColor,
            position: "absolute",
            bottom: "0px",
            left: "15px",
            borderColor: props.extraPaperColor,
            borderWidth: "1px",
            borderStyle: "solid",
          }}
        />

        <Box
          sx={{
            width: "14px",
            height: "14px",
            borderRadius: "100px",
            backgroundColor: props.primaryColor,
            position: "absolute",
            bottom: "5px",
            left: "0px",
            borderColor: props.extraPaperColor,
            borderWidth: "1px",
            borderStyle: "solid",
          }}
        />
      </Box>
    </>
  );
};

export const ThemeSelector: React.FC<{
  selectedThemeId?: string;
  onPreview: (themeId?: string) => void;
  onSelect: (themeId: string) => void;
}> = (props) => {
  const allThemes = useUserThemes();

  if (allThemes.isLoading) {
    return <>Loading...</>;
  }

  return (
    <Grid
      container
      sx={{
        gap: 2,
      }}
    >
      {allThemes.userThemes.map((x) => {
        return (
          <Grid item>
            <ThemeIcon
              selected={x.id === props.selectedThemeId}
              primaryColor={x.primaryColor}
              secondaryColor={x.secondaryColor}
              bgIcon={x.menuIcon}
              extraPaperColor={x.extraPaperColor}
              onHover={() => {
                props.onPreview(x.id);
              }}
              onHoverOut={() => props.onPreview(undefined)}
              onSelect={() => props.onSelect(x.id)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
