import {
  ReactNode,
  Suspense,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ExpandGlowJwt, GlowJwtContext } from "./jwt/GlowJWTContext";
import { Jwt, PrincipalType } from "./codegen/graphql";
import { GlowDarkTheme } from "./ui-kit/themes/GlowDarkTheme";
import { GetGlowLightThemeCopy } from "./ui-kit/themes/GlowLightTheme";
import {
  Box,
  CssBaseline,
  Theme,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { GlowAppBar } from "./ui-kit/smart-components/GlowAppBar";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  GlowApolloProvider,
  clearGlowJWT,
  getGlowJWT,
  hasUserEverLoggedIn,
  setGlowJWT,
} from "./apollo";
import { GetGlowTheme, SetGlowTheme } from "./ui-kit/themes/theme";
import { Route, Routes, useLocation } from "react-router-dom";
import "@fontsource/josefin-sans";
import "@fontsource/comfortaa";
import { gql } from "./codegen/gql";
import { useMutation } from "@apollo/client";
import moment from "moment";
import LogRocket from "logrocket";
import { appInsights } from "./appInsights";
import { useSanityLocalStrings } from "./cms/queries/getLocalizedStrings";
import { BlogView2 } from "./ui-kit/smart-components/Blog";
import { initParticlesEngine } from "@tsparticles/react";


import {ViewEventWrapper2} from "./ui-kit/smart-components/ViewEvent";

const LazyCreatePollForm = lazy(
  () => import("./ui-kit/smart-components/CreatePollForm2")
);
const LazyViewEventWrapper = lazy(
  () => import("./ui-kit/smart-components/ViewEvent")
);
const LazyViewEventsList = lazy(
  () => import("./ui-kit/smart-components/ViewEventsList")
);
const LazyLandinPage = lazy(() => import("./ui-kit/landing/LandingPage"));
const LazyPrivacyPolicy = lazy(() => import("./ui-kit/landing/PrivacyPolicy"));
const LazyTermsAndConditions = lazy(
  () => import("./ui-kit/landing/TermsAndConditions")
);
const LazyProfileView = lazy(
  () => import("./ui-kit/smart-components/ProfileView")
);
const LazyCreateEventForm = lazy(
  () => import("./ui-kit/smart-components/CreateEventForm")
);

const LazyCreatePage = lazy(
  () => import("./ui-kit/smart-components/CreatePage")
);
const LazyViewPoll = lazy(() => import("./ui-kit/smart-components/ViewPoll"));
const LazyViewPolls = lazy(
  () => import("./ui-kit/smart-components/ViewedPollsList")
);

const EPHEMERAL_LOGIN_QUERY_V3 = gql(`
  mutation loginAsEphemeralUserV3
  {
    loginAsEphemeralUser
    {
      principalType
      token
    }
  }
`);

interface EphemeralLoginBootstrapProps {
  children: ReactNode;
}

const EphemeralLoginBootstrap: React.FC<EphemeralLoginBootstrapProps> = (
  props
) => {
  const [ephemeralQuery, ephemeralQueryState] = useMutation(
    EPHEMERAL_LOGIN_QUERY_V3
  );
  const context = useContext(GlowJwtContext);

  useEffect(() => {
    if (!context.jwt) {
      ephemeralQuery({
        onCompleted: (data) => {
          context.setJwt(data.loginAsEphemeralUser);
        },
      });
    }
  }, [context.jwt?.token ?? moment()]);

  return <>{context.jwt && props.children}</>;
};

export const AppV2: React.FC = () => {
  const [glowJwtContextValue, setGlowJWTContextValue] = useState<
    Jwt | undefined
  >(getGlowJWT() ?? undefined);
  const [returningUser, setReturningUser] = useState(hasUserEverLoggedIn());
  const [themeMode, setThemeMode] = useState<"light" | "dark">(GetGlowTheme());


  const themeOptions = themeMode === "dark" ? GlowDarkTheme : GetGlowLightThemeCopy();

  const themeCopy = JSON.parse(JSON.stringify(themeOptions));

  const theme = createTheme(themeCopy);

  const location = useLocation();

  const showLanding =
    location.pathname === "/" &&
    glowJwtContextValue?.principalType !== PrincipalType.Verified;

  const localStrings = useSanityLocalStrings();

  const [activeApolloCount, setActiveApolloCount] = useState(0);

  // Set the log rocket stuff if we change users
  useEffect(() => {
    const user = ExpandGlowJwt(glowJwtContextValue?.token);
    if (user.profile) {
      var traits = {};
      if (user.sms) {
        traits = {
          sms: user.sms,
        };
      }
      LogRocket.identify(user.profile, traits);
      appInsights.setAuthenticatedUserContext(user.profile);
    }
  }, [glowJwtContextValue?.token]);


  return (
    <GlowJwtContext.Provider
      value={{
        themeMode: themeMode,
        setThemeMode: (mode: "light" | "dark") => {
          // Theme is always light for now
          mode = "light";
          SetGlowTheme(mode);
          setThemeMode(mode);
        },
        returningUser: returningUser,
        jwt: glowJwtContextValue,
        setJwt: (jwt: Jwt | undefined) => {
          setGlowJWTContextValue(jwt);
          setReturningUser(hasUserEverLoggedIn());
          if (jwt) {
            setGlowJWT(jwt);
          } else {
            clearGlowJWT();
          }
        },
      }}
    >
      <GlowApolloProvider>
        <EphemeralLoginBootstrap>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GlowAppBar />

              <Box
                id="routeContainer"
                sx={{
                  position: "fixed",
                  width: "100%",
                  zIndex: 1000,
                  top: "56px",
                  overflowY: "auto",
                  height: "calc(100% - 56px)",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                  display: localStrings.loading ? "none" : "flex",
                }}
              >
                <Suspense fallback={<div></div>}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        showLanding ? <LazyLandinPage /> : <LazyCreatePage />
                      }
                    />

                    <Route path="/create" element={<LazyCreatePage />} />
                    <Route path="/blog" element={<BlogView2 />} />
                    <Route path="/events" element={<LazyViewEventsList />} />
                    <Route path="/profile" element={<LazyProfileView />} />
                    <Route path="/polls" element={<LazyViewPolls />} />
                    <Route path="/p/:shortcode" element={<LazyViewPoll />} />
                    <Route path="/terms" element={<LazyTermsAndConditions />} />
                    <Route path="/privacy" element={<LazyPrivacyPolicy />} />
                    <Route
                      path="/:shortcode"
                      element={<LazyViewEventWrapper />}
                    />
                    <Route
                      path="/e/:shortcode"
                      element={<ViewEventWrapper2 />}
                    />
                  </Routes>
                </Suspense>
              </Box>
              {/* {!window.location.href.includes("/create") &&
                !(window.location.href.endsWith(".rsvp") && !showLanding) &&
                !(window.location.href.endsWith(".rsvp/") && !showLanding) &&
                GlowParticles(particlesInit, particlesLoaded, theme)} */}
            </ThemeProvider>
          </LocalizationProvider>
        </EphemeralLoginBootstrap>
      </GlowApolloProvider>
    </GlowJwtContext.Provider>
  );
};

