import moment from "moment";
import { EventDetails, EventDetailsV2 } from "../components/EventDetailsView";
import { GlowColumnBox, GlowRowBox } from "../components/GlowBox";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  ComponentsOverrides,
  CssBaseline,
  Dialog,
  FormControlLabel,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SimplePaletteColorOptions,
  Slide,
  SxProps,
  Theme,
  ThemeOptions,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { gql } from "../../codegen/gql";
import { useMutation, useQuery } from "@apollo/client";
import {
  EventView,
  EventViewStatus,
  PrincipalType,
  ReadEventV3Query,
  ReservationStatus,
} from "../../codegen/graphql";
import { ShareEventDialog } from "../components/ShareDialog";
import { useContext, useRef, useState } from "react";
import { RSVPRow } from "../components/RSVPRow";
import { EventPatchForm, EventPatchBox } from "./EventPatchForm";
import { HostIcon } from "../components/ResponseIcons";
import { HostFeatures, EditEventAdvancedBox } from "./HostFeatures";
import { atcb_action } from "add-to-calendar-button";
import React from "react";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import { GlowJwtContext } from "../../jwt/GlowJWTContext";
import { RSVPClickThroughDiaglog } from "../components/RSVPClickThroughDialog";
import { OutlookLogo } from "../components/Logo";
import { GuestEdit, GuestEditV2 } from "./GuestEdit";
import { RSVPViewV2, RSVPViewV3 } from "../components/RSVPViewV2";
import { GlowSecondaryPaper } from "../components/GlowSecondaryPaper";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { PlusOneWaitlistDialog } from "../components/PlusOneWaitlistDialog";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import {
  TextGuestDialog,
  TextGuestSummary,
} from "../components/TextGuestsDialog";

import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import { useSanityLocalStrings } from "../../cms/queries/getLocalizedStrings";
import { InfoComponent } from "../components/InfoComponent";
import { GlowChrome } from "../components/GlowChrome";
import { ErrorBox } from "../components/ErrorBox";
import { InfoBox } from "../components/InfoBox";
import TransitionGroup from "react-transition-group/TransitionGroup";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import QuestionMark from "@mui/icons-material/QuestionMark";
import { GetGlowLightThemeCopy } from "../themes/GlowLightTheme";
import { useUserThemes } from "../../cms/queries/getUserThemes";

const READ_EVENT_QUERY_V3 = gql(`
query readEventV3($eventCode: String!)
{
	getGlowEvent(eventCode: $eventCode)
	{
		eventCode,
		name,
		isoTime,
    timeZone,
		editable,
    attendanceCap,
		eventLocation,
    eventLocationMapUrl,
    locationAddress,
    description,
    status,
    reservationStatus,
    showAccepts,
    showMaybes,
    showDeclines,
    themeId,
    hostV2
    {
      name,
      sms
    },
    waitList,
    reservationDetails{
			capacity
			attendeeCount
			yesCount
			noCount
			maybeCount
			waitlistCount
      yesReservation{
        profileId
				name
				numerOfGuests
        currentUser
        status
        phone10
      }
      noReservation{
        profileId
				name
				numerOfGuests
        currentUser
        status
        phone10
      }
      maybeReservation{
        profileId
        name
        numerOfGuests
        currentUser
        status
        phone10
      }
      waitlistReservation{
        profileId
        name
        numerOfGuests
        currentUser
        status
        phone10
      }
    }
	}
}
`);

const VERIFIED_RSVP_MUTATION = gql(`
  mutation verifedUserRSVPV3($eventCode:String!, $status:ReservationStatus!, $plusOne:Boolean)
  {
    rsvpToEvent(eventCode:$eventCode, status:$status, plusOne:$plusOne)
  }
`);

const EPHEMERAL_RSVP_MUTATION = gql(`
  mutation ephemeralRSVPV3($eventCode:String!, $status:ReservationStatus!, $name:String!)
  {
    rsvpToEvent(eventCode:$eventCode, status:$status, name:$name)
  }
`);

interface ViewEventProps {
  eventId: string;
}

export const ViewEvent: React.FC<ViewEventProps> = (props) => {
  const isMobileSize = useMediaQuery("(max-width:767px)");
  const context = useContext(GlowJwtContext);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [textGuestOpen, setTextGuestOpen] = useState(false);
  const [plusOneConfirmationDialogOpen, setPlusOneConfirmationDialogOpen] =
    useState(false);
  const [removePlusOneOpen, setRemovePlusOneOpen] = useState(false);
  const [mode, setMode] = useState<
    undefined | "view" | "host-features" | "guestedit" | "textguest"
  >();
  const { data, loading, error, refetch } = useQuery(READ_EVENT_QUERY_V3, {
    variables: {
      eventCode: props.eventId,
    },
  });

  const [verifiedRSVP, verifiedRSVPState] = useMutation(VERIFIED_RSVP_MUTATION);
  const [ephemeralRSVP, ephemeralRSVPState] = useMutation(
    EPHEMERAL_RSVP_MUTATION
  );

  const allReservations = [
    ...(data?.getGlowEvent?.reservationDetails?.yesReservation ?? []),
    ...(data?.getGlowEvent?.reservationDetails?.noReservation ?? []),
    ...(data?.getGlowEvent?.reservationDetails?.maybeReservation ?? []),
    ...(data?.getGlowEvent?.reservationDetails?.waitlistReservation ?? []),
  ];

  const currentUserStatus =
    allReservations.find((x) => x.currentUser)?.status ??
    ReservationStatus.Unknown;

  const currentUserName = allReservations.find((x) => x.currentUser)?.name;

  const currentUserNumberOfGuests =
    allReservations.find((x) => x.currentUser)?.numerOfGuests ?? 0;

  const [selectedStatus, setSelectedStatus] =
    useState<ReservationStatus>(currentUserStatus);

  const [rsvpClickThroughDialogOpen, setRSVPClickThroughDialogOpen] =
    useState(false);

  const isLoggedIn = Boolean(
    context.jwt?.principalType == PrincipalType.Verified
  );

  const [calendarMenuAnchorElement, setCalendarMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const eventCanceled = data?.getGlowEvent.status === EventViewStatus.Cancelled;

  const eventAtCapacity =
    (data?.getGlowEvent.reservationDetails?.capacity != undefined &&
      data?.getGlowEvent.reservationDetails?.attendeeCount >=
        data?.getGlowEvent.reservationDetails?.capacity) ??
    false;

  const showWaitlist =
    (eventAtCapacity ||
      (data?.getGlowEvent.reservationDetails?.waitlistCount ?? 0) > 0) &&
    currentUserStatus != ReservationStatus.Yes;

  return (
    <>
      <GlowChrome
        navigationFAB={true}
        animation={true}
        shareAction={
          data?.getGlowEvent.editable
            ? () => setShareDialogOpen(true)
            : undefined
        }
      />
      <ShareEventDialog
        eventCode={props.eventId}
        eventTitle={data?.getGlowEvent.name ?? ""}
        isoDate={moment(data?.getGlowEvent.isoTime)}
        locationName={data?.getGlowEvent.eventLocation ?? ""}
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
      />

      <Dialog
        PaperProps={{
          sx: {
            width: "100%",
            margin: "1vw",
            maxWidth: "500px",
          },
        }}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      >
        {data && (
          <EventPatchForm
            onSaved={() => {
              refetch();
              setEditDialogOpen(false);
            }}
            eventCode={props.eventId}
            initialName={data!.getGlowEvent.name}
            initialDateTime={moment(data?.getGlowEvent.isoTime)}
            initialLocationName={data!.getGlowEvent.eventLocation}
            initialLocationMapUrl={
              data?.getGlowEvent.eventLocationMapUrl ?? undefined
            }
            initialLocationAddress={
              data?.getGlowEvent.locationAddress ?? undefined
            }
            initialDescription={data?.getGlowEvent.description}
            initialTimeZone={data?.getGlowEvent.timeZone ?? moment.tz.guess()}
          />
        )}
      </Dialog>
      <Menu
        open={Boolean(calendarMenuAnchorElement)}
        anchorEl={calendarMenuAnchorElement}
        onClose={() => setCalendarMenuAnchorElement(null)}
      >
        {["Apple", "Google", "iCal", "Outlook.com"].map((x, index) => {
          const iconMap = {
            Apple: <AppleIcon />,
            Google: <GoogleIcon />,
            iCal: <EventRoundedIcon />,
            "Outlook.com": <OutlookLogo />,
          };
          const icon = (iconMap as any)[x];
          return (
            <MenuItem
              key={index}
              onClick={() => {
                const start = moment(data?.getGlowEvent?.isoTime);
                const end = moment(data?.getGlowEvent?.isoTime).add(1, "hour");

                const calendarName = `${
                  data?.getGlowEvent.name ?? "Invitation"
                }: (from www.glow.rsvp)`;
                var host = window.location.host;
                if (host.startsWith("www.")) {
                  host = host.substring(4);
                }
                const eventLink = `${window.location.protocol}//${host}/${props.eventId}`;
                const description = `[strong]${data?.getGlowEvent.name}[/strong]
[p]${data?.getGlowEvent.description}[br][br]See all details at [url]${eventLink}[/url][/p]`;

                atcb_action({
                  name: calendarName,
                  startDate: start.format("YYYY-MM-DD"),
                  startTime: start.format("HH:mm"),
                  endDate: end.format("YYYY-MM-DD"),
                  endTime: end.format("HH:mm"),
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  location:
                    data?.getGlowEvent.eventLocationMapUrl ??
                    data?.getGlowEvent.eventLocation ??
                    "",
                  // options: ['Apple', 'Google', 'iCal', "Microsoft365", "Outlook.com"],
                  options: [x as any],
                  description: description,
                });

                setCalendarMenuAnchorElement(null);
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{x}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>

      <PlusOneWaitlistDialog
        open={plusOneConfirmationDialogOpen}
        onConfirm={() => {
          verifiedRSVP({
            variables: {
              eventCode: props.eventId,
              status: ReservationStatus.Yes,
              plusOne: true,
            },
            onCompleted: () => {
              setPlusOneConfirmationDialogOpen(false);
              refetch();
            },
          });
        }}
        onCancel={() => {
          setPlusOneConfirmationDialogOpen(false);
        }}
      />

      <ConfirmationDialog
        open={removePlusOneOpen}
        header="Are you sure?"
        message="This eve now has a waitlist, so adding a +1 later would move you to the wailist."
        onConfirm={() => {
          verifiedRSVP({
            variables: {
              eventCode: props.eventId,
              status: ReservationStatus.Yes,
              plusOne: false,
            },
            onCompleted: () => {
              setRemovePlusOneOpen(false);
              refetch();
            },
          });
        }}
        onCancel={() => {
          setRemovePlusOneOpen(false);
        }}
        bias="cancel"
        okText="Yes, remove +1"
        cancelText="Nevermind"
      />

      <Slide in={error !== undefined} direction="up" unmountOnExit>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ErrorBox
            errorMessages={[
              "We can't find the event you are looking for.",
              "The event creator may have sent you the wrong link.",
            ]}
          />
        </Box>
      </Slide>

      <Slide
        in={data !== undefined && (mode === undefined || mode == "view")}
        direction={mode == undefined ? "up" : "right"}
        mountOnEnter
        unmountOnExit
      >
        <Box>
          <GlowColumnBox>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {data?.getGlowEvent.editable && (
                <Button
                  variant="outlined"
                  startIcon={<HostIcon />}
                  onClick={() => {
                    window.history.pushState({}, "");
                    setMode("host-features");
                  }}
                >
                  Host Features
                </Button>
              )}
            </Box>
            <EventDetails
              title={data?.getGlowEvent.name ?? ""}
              date={moment(data?.getGlowEvent.isoTime)}
              timeZone={data?.getGlowEvent.timeZone ?? moment.tz.guess()}
              locationName={data?.getGlowEvent.eventLocation ?? ""}
              locationMapUrl={data?.getGlowEvent.eventLocationMapUrl ?? ""}
              locationAddress={data?.getGlowEvent.locationAddress ?? ""}
              description={data?.getGlowEvent.description ?? ""}
              showEdit={data?.getGlowEvent.editable}
              editClicked={() => {
                setEditDialogOpen(true);
              }}
              canceled={data?.getGlowEvent.status == EventViewStatus.Cancelled}
            />
            {!data?.getGlowEvent.editable && (
              <>
                <RSVPClickThroughDiaglog
                  showWaitlist={showWaitlist}
                  status={selectedStatus}
                  onStatusChange={(status) => {
                    setSelectedStatus(status);
                  }}
                  open={rsvpClickThroughDialogOpen}
                  onClose={() => {
                    setSelectedStatus(ReservationStatus.Unknown);
                    setRSVPClickThroughDialogOpen(false);
                  }}
                  onRSVPViaVerified={(jwt, plusOne) => {
                    verifiedRSVP({
                      context: {
                        headers: {
                          authorization: `Bearer ${jwt.token}`,
                        },
                      },
                      variables: {
                        eventCode: props.eventId,
                        status: selectedStatus,
                        plusOne: plusOne,
                      },
                      onCompleted: () => {
                        refetch();
                      },
                    });
                    setRSVPClickThroughDialogOpen(false);
                  }}
                  onRSVPEphemeral={(name) => {
                    ephemeralRSVP({
                      variables: {
                        eventCode: props.eventId,
                        status: selectedStatus,
                        name: name,
                      },
                      onCompleted: () => {
                        refetch();
                      },
                    });
                    setRSVPClickThroughDialogOpen(false);
                  }}
                />
                <RSVPRow
                  showWaitlist={showWaitlist}
                  selected={currentUserStatus}
                  onChange={(status) => {
                    setSelectedStatus(status);
                    if (isLoggedIn) {
                      verifiedRSVP({
                        variables: {
                          eventCode: props.eventId,
                          status: status,
                          plusOne: currentUserNumberOfGuests > 1,
                        },
                        onCompleted: () => {
                          refetch();
                        },
                      });
                    } else {
                      if (
                        currentUserStatus === ReservationStatus.Unknown ||
                        currentUserName === undefined
                      ) {
                        setRSVPClickThroughDialogOpen(true);
                      } else {
                        ephemeralRSVP({
                          variables: {
                            eventCode: props.eventId,
                            status: status,
                            name: currentUserName,
                          },
                          onCompleted: () => {
                            refetch();
                          },
                          onError: (e) => {
                            refetch();
                          },
                        });
                      }
                    }
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  {![ReservationStatus.Unknown, ReservationStatus.No].includes(
                    currentUserStatus
                  ) && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={currentUserNumberOfGuests > 1}
                          onClick={(x) => {
                            if (
                              currentUserStatus == ReservationStatus.Yes &&
                              currentUserNumberOfGuests == 1 &&
                              (eventAtCapacity ||
                                (data?.getGlowEvent?.reservationDetails
                                  ?.waitlistCount ?? 0) > 0)
                            ) {
                              setPlusOneConfirmationDialogOpen(true);
                              x.preventDefault();
                            } else if (
                              currentUserStatus == ReservationStatus.Yes &&
                              currentUserNumberOfGuests > 1 &&
                              (data?.getGlowEvent?.reservationDetails
                                ?.waitlistCount ?? 0) > 0
                            ) {
                              setRemovePlusOneOpen(true);
                              x.preventDefault();
                            } else {
                              verifiedRSVP({
                                variables: {
                                  eventCode: props.eventId,
                                  status: currentUserStatus,
                                  plusOne: !(currentUserNumberOfGuests > 1),
                                },
                                onCompleted: () => {
                                  refetch();
                                },
                              });
                            }
                          }}
                          checked={currentUserNumberOfGuests > 1}
                        />
                      }
                      label="Bringing a +1"
                    />
                  )}
                </Box>
              </>
            )}
            {data?.getGlowEvent.editable && (
              <GlowRowBox
                sx={{ width: "100%", maxWidth: "400px", margin: "0px" }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(x) => {
                    setCalendarMenuAnchorElement(x.currentTarget);
                  }}
                  endIcon={<ArrowDropDownRoundedIcon />}
                >
                  Add to Calendar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setShareDialogOpen(true);
                  }}
                  fullWidth
                  endIcon={<GroupAddRoundedIcon />}
                >
                  Invite
                </Button>
              </GlowRowBox>
            )}

            {data?.getGlowEvent.editable &&
              (data?.getGlowEvent?.reservationDetails?.waitlistCount ?? 0) >
                0 && (
                <GlowSecondaryPaper
                  sx={{
                    width: "100%",
                    maxWidth: "500px",
                  }}
                >
                  <GlowRowBox>
                    <InfoRoundedIcon color="secondary" />
                    <Typography variant="body2">
                      Your event has a waitlist! Use{" "}
                      <Link
                        onClick={() => {
                          window.history.pushState({}, "");
                          setMode("guestedit");
                        }}
                      >
                        Edit Guest Status
                      </Link>{" "}
                      button below to manage the waitlist.
                    </Typography>
                  </GlowRowBox>
                </GlowSecondaryPaper>
              )}

            <RSVPViewV2
              host={
                data?.getGlowEvent.hostV2
                  ?.filter((r) => r.name)
                  .map((r) => r.name!) ?? []
              }
              isHost={data?.getGlowEvent.editable ?? false}
              capacity={
                data?.getGlowEvent.reservationDetails?.capacity ?? undefined
              }
              attending={
                data?.getGlowEvent.reservationDetails?.attendeeCount ?? 0
              }
              yesCount={data?.getGlowEvent.reservationDetails?.yesCount ?? 0}
              noCount={data?.getGlowEvent.reservationDetails?.noCount ?? 0}
              maybeCount={
                data?.getGlowEvent.reservationDetails?.maybeCount ?? 0
              }
              waitlistCount={
                data?.getGlowEvent.reservationDetails?.waitlistCount ?? 0
              }
              yesReservations={
                data?.getGlowEvent.reservationDetails?.yesReservation ?? []
              }
              noReservations={
                data?.getGlowEvent.reservationDetails?.noReservation ?? []
              }
              waitlistReservations={
                data?.getGlowEvent.reservationDetails?.waitlistReservation ?? []
              }
              maybeReservations={
                data?.getGlowEvent.reservationDetails?.maybeReservation ?? []
              }
              options={{
                showAccepts:
                  data?.getGlowEvent.editable ||
                  (data?.getGlowEvent.showAccepts ?? false),
                showDeclines:
                  data?.getGlowEvent.editable ||
                  (data?.getGlowEvent.showDeclines ?? false),
                showMaybes:
                  data?.getGlowEvent.editable ||
                  (data?.getGlowEvent.showMaybes ?? false),
                showWaitlist: data?.getGlowEvent.editable ?? false,
              }}
            />
            {!data?.getGlowEvent.editable && (
              <>
                <Button
                  variant="outlined"
                  onClick={(x) => {
                    setCalendarMenuAnchorElement(x.currentTarget);
                  }}
                >
                  Add to Calendar
                </Button>
                <Button
                  variant="text"
                  onClick={() => {
                    setShareDialogOpen(true);
                  }}
                  endIcon={<ShareIcon />}
                >
                  Share
                </Button>
              </>
            )}
            {data?.getGlowEvent.editable && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.history.pushState({}, "");
                    setMode("guestedit");
                  }}
                >
                  Edit Guest Status
                </Button>

                {isMobileSize && (
                  <Button
                    variant="text"
                    onClick={() => {
                      window.history.pushState({}, "");
                      setMode("textguest");
                    }}
                    endIcon={
                      <InfoComponent
                        helpBlob={
                          <>
                            Select recipients, compose your message, and
                            initiate a group chat using iMessage (currently only
                            functional for iOS!)
                          </>
                        }
                      />
                    }
                  >
                    Text Guests
                  </Button>
                )}
              </>
            )}
          </GlowColumnBox>
        </Box>
      </Slide>
      <Slide
        in={mode === "guestedit"}
        direction="left"
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <GuestEdit
            onClose={() => {
              setMode("view");
              refetch();
            }}
            eventId={props.eventId}
          />
        </Box>
      </Slide>
      <Slide
        in={mode == "textguest"}
        direction="left"
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <TextGuestDialog
            open={textGuestOpen}
            onClose={() => {
              setMode("view");
              refetch();
            }}
            yesRSVPs={
              data?.getGlowEvent.reservationDetails?.yesReservation ?? []
            }
            noRSVPS={data?.getGlowEvent.reservationDetails?.noReservation ?? []}
            maybeRSVPs={
              data?.getGlowEvent.reservationDetails?.maybeReservation ?? []
            }
            waitlistRSVPs={
              data?.getGlowEvent.reservationDetails?.waitlistReservation ?? []
            }
          />
        </Box>
      </Slide>
      <Slide
        in={mode == "host-features"}
        direction="left"
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <HostFeatures
            eventCode={props.eventId}
            onClose={() => {
              refetch();
              setMode("view");
            }}
          />
        </Box>
      </Slide>
    </>
  );
};

export const ViewEventWrapper = () => {
  const { shortcode } = useParams();
  return <ViewEvent eventId={shortcode!} />;
};

const NavChip: React.FC<{
  label: string;
  active: boolean;
  onClick: () => void;
}> = (props) => {
  const sharedSx: SxProps<Theme> = {
    padding: "4px 8px",
    borderRadius: "20px",
    cursor: "pointer",
    fontWeight: "900",
    fontSize: "14px",
    userSelect: "none",
    border: "white solid 1px",
  };

  const activeSx: SxProps<Theme> = {
    ...sharedSx,
    background: (t) => t.palette.extraPaperColor.main,
    color: (t) => t.palette.primary.main,
    boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.25)",
  };

  const inactiveSx: SxProps<Theme> = {
    ...sharedSx,
    background: (t) =>
      t.palette.extraPaperColor.main.substring(0, 7).concat("88"),
      boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.25)",
  };

  return (
    <Box
      component="span"
      sx={props.active ? activeSx : inactiveSx}
      onClick={props.onClick}
    >
      {props.label}
    </Box>
  );
};

type EventViewNav = "Preview" | "Edit" | "Guest Details";
const NavBox: React.FC<{
  active: EventViewNav;
  setActive: (nav: EventViewNav) => void;
}> = ({ active, setActive }) => {
  const sxSelector = (nav: EventViewNav, current: EventViewNav) => {
    if (nav === current) {
      const sxProps: SxProps<Theme> = {
        background: "white",
        color: (t) => t.palette.primary.main,
        fontWeight: "900",
        borderRadius: "14px",
      };

      return sxProps;
    }
    const sxProps: SxProps<Theme> = {
      borderColor: "white",
      borderWidth: "1px",
      borderStyle: "solid",
    };
    return sxProps;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "left",
        gap: "8px",
      }}
    >
      <NavChip
        label="Preview"
        active={active == "Preview"}
        onClick={() => setActive("Preview")}
      />
      <NavChip
        label="Edit Event"
        active={active == "Edit"}
        onClick={() => setActive("Edit")}
      />
      <NavChip
        label="Detailed Guest View"
        active={active == "Guest Details"}
        onClick={() => setActive("Guest Details")}
      />
    </Box>
  );
};

const ViewEvent2Guest: React.FC<{
  eventView: ReadEventV3Query;
  refetch: () => void;
}> = (props) => {
  const data = props.eventView;
  const refetch = props.refetch;

  const isMobileSize = useMediaQuery("(max-width:767px)");
  const context = useContext(GlowJwtContext);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [textGuestOpen, setTextGuestOpen] = useState(false);
  const [plusOneConfirmationDialogOpen, setPlusOneConfirmationDialogOpen] =
    useState(false);
  const [removePlusOneOpen, setRemovePlusOneOpen] = useState(false);
  const [mode, setMode] = useState<
    undefined | "view" | "host-features" | "guestedit" | "textguest"
  >();

  const [verifiedRSVP, verifiedRSVPState] = useMutation(VERIFIED_RSVP_MUTATION);
  const [ephemeralRSVP, ephemeralRSVPState] = useMutation(
    EPHEMERAL_RSVP_MUTATION
  );

  const allReservations = [
    ...(data?.getGlowEvent?.reservationDetails?.yesReservation ?? []),
    ...(data?.getGlowEvent?.reservationDetails?.noReservation ?? []),
    ...(data?.getGlowEvent?.reservationDetails?.maybeReservation ?? []),
    ...(data?.getGlowEvent?.reservationDetails?.waitlistReservation ?? []),
  ];

  const currentUserStatus =
    allReservations.find((x) => x.currentUser)?.status ??
    ReservationStatus.Unknown;

  const currentUserName = allReservations.find((x) => x.currentUser)?.name;

  const currentUserNumberOfGuests =
    allReservations.find((x) => x.currentUser)?.numerOfGuests ?? 0;

  const [selectedStatus, setSelectedStatus] =
    useState<ReservationStatus>(currentUserStatus);

  const [rsvpClickThroughDialogOpen, setRSVPClickThroughDialogOpen] =
    useState(false);

  const isLoggedIn = Boolean(
    context.jwt?.principalType == PrincipalType.Verified
  );

  const [calendarMenuAnchorElement, setCalendarMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const eventCanceled = data?.getGlowEvent.status === EventViewStatus.Cancelled;

  const eventAtCapacity =
    (data?.getGlowEvent.reservationDetails?.capacity != undefined &&
      data?.getGlowEvent.reservationDetails?.attendeeCount >=
        data?.getGlowEvent.reservationDetails?.capacity) ??
    false;

  const showWaitlist =
    (eventAtCapacity ||
      (data?.getGlowEvent.reservationDetails?.waitlistCount ?? 0) > 0) &&
    currentUserStatus != ReservationStatus.Yes;
  return (
    <>
      <EventDetailsV2
        eventId={data.getGlowEvent.eventCode}
        title={data?.getGlowEvent.name ?? ""}
        date={moment(data?.getGlowEvent.isoTime)}
        timeZone={data?.getGlowEvent.timeZone ?? moment.tz.guess()}
        locationName={data?.getGlowEvent.eventLocation ?? ""}
        locationMapUrl={data?.getGlowEvent.eventLocationMapUrl ?? ""}
        locationAddress={data?.getGlowEvent.locationAddress ?? ""}
        description={data?.getGlowEvent.description ?? ""}
        showEdit={data?.getGlowEvent.editable}
        canceled={data?.getGlowEvent.status == EventViewStatus.Cancelled}
      />

      {/* This is the RSVP portion */}
      <>
        <RSVPClickThroughDiaglog
          showWaitlist={showWaitlist}
          status={selectedStatus}
          onStatusChange={(status) => {
            setSelectedStatus(status);
          }}
          open={rsvpClickThroughDialogOpen}
          onClose={() => {
            console.log("onClose");
            setSelectedStatus(ReservationStatus.Unknown);
            setRSVPClickThroughDialogOpen(false);
          }}
          onRSVPViaVerified={(jwt, plusOne) => {
            verifiedRSVP({
              context: {
                headers: {
                  authorization: `Bearer ${jwt.token}`,
                },
              },
              variables: {
                eventCode: data.getGlowEvent.eventCode,
                status: selectedStatus,
                plusOne: plusOne,
              },
              onCompleted: () => {
                refetch();
              },
            });
            setRSVPClickThroughDialogOpen(false);
          }}
          onRSVPEphemeral={(name) => {
            ephemeralRSVP({
              variables: {
                eventCode: data.getGlowEvent.eventCode,
                status: selectedStatus,
                name: name,
              },
              onCompleted: () => {
                refetch();
              },
            });
            setRSVPClickThroughDialogOpen(false);
          }}
        />

        <RSVPRow
          showWaitlist={showWaitlist}
          selected={currentUserStatus}
          onChange={(status) => {
            setSelectedStatus(status);
            if (isLoggedIn) {
              verifiedRSVP({
                variables: {
                  eventCode: data.getGlowEvent.eventCode,
                  status: status,
                  plusOne: currentUserNumberOfGuests > 1,
                },
                onCompleted: () => {
                  refetch();
                },
              });
            } else {
              if (
                currentUserStatus === ReservationStatus.Unknown ||
                currentUserName === undefined
              ) {
                setRSVPClickThroughDialogOpen(true);
              } else {
                ephemeralRSVP({
                  variables: {
                    eventCode: data.getGlowEvent.eventCode,
                    status: status,
                    name: currentUserName,
                  },
                  onCompleted: () => {
                    refetch();
                  },
                  onError: (e) => {
                    refetch();
                  },
                });
              }
            }
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          {![ReservationStatus.Unknown, ReservationStatus.No].includes(
            currentUserStatus
          ) && (
            <FormControlLabel
              control={
                <Checkbox
                  sx={{}}
                  defaultChecked={currentUserNumberOfGuests > 1}
                  onClick={(x) => {
                    if (
                      currentUserStatus == ReservationStatus.Yes &&
                      currentUserNumberOfGuests == 1 &&
                      (eventAtCapacity ||
                        (data?.getGlowEvent?.reservationDetails
                          ?.waitlistCount ?? 0) > 0)
                    ) {
                      setPlusOneConfirmationDialogOpen(true);
                      x.preventDefault();
                    } else if (
                      currentUserStatus == ReservationStatus.Yes &&
                      currentUserNumberOfGuests > 1 &&
                      (data?.getGlowEvent?.reservationDetails?.waitlistCount ??
                        0) > 0
                    ) {
                      setRemovePlusOneOpen(true);
                      x.preventDefault();
                    } else {
                      verifiedRSVP({
                        variables: {
                          eventCode: data.getGlowEvent.eventCode,
                          status: currentUserStatus,
                          plusOne: !(currentUserNumberOfGuests > 1),
                        },
                        onCompleted: () => {
                          refetch();
                        },
                      });
                    }
                  }}
                  checked={currentUserNumberOfGuests > 1}
                />
              }
              label={
                <Typography
                  sx={{
                    color: (t) => t.palette.primary.contrastText,
                  }}
                >
                  Bringing a +1
                </Typography>
              }
            />
          )}
        </Box>
      </>

      {/* User Summary */}
      <RSVPViewV3
        host={
          data?.getGlowEvent.hostV2
            ?.filter((r) => r.name)
            .map((r) => r.name!) ?? []
        }
        isHost={data?.getGlowEvent.editable ?? false}
        capacity={data?.getGlowEvent.reservationDetails?.capacity ?? undefined}
        attending={data?.getGlowEvent.reservationDetails?.attendeeCount ?? 0}
        yesCount={data?.getGlowEvent.reservationDetails?.yesCount ?? 0}
        noCount={data?.getGlowEvent.reservationDetails?.noCount ?? 0}
        maybeCount={data?.getGlowEvent.reservationDetails?.maybeCount ?? 0}
        waitlistCount={
          data?.getGlowEvent.reservationDetails?.waitlistCount ?? 0
        }
        yesReservations={
          data?.getGlowEvent.reservationDetails?.yesReservation ?? []
        }
        noReservations={
          data?.getGlowEvent.reservationDetails?.noReservation ?? []
        }
        waitlistReservations={
          data?.getGlowEvent.reservationDetails?.waitlistReservation ?? []
        }
        maybeReservations={
          data?.getGlowEvent.reservationDetails?.maybeReservation ?? []
        }
        options={{
          showAccepts:
            data?.getGlowEvent.editable ||
            (data?.getGlowEvent.showAccepts ?? false),
          showDeclines:
            data?.getGlowEvent.editable ||
            (data?.getGlowEvent.showDeclines ?? false),
          showMaybes:
            data?.getGlowEvent.editable ||
            (data?.getGlowEvent.showMaybes ?? false),
          showWaitlist: data?.getGlowEvent.editable ?? false,
        }}
      />
    </>
  );
};

const EditGuestView2: React.FC<{
  eventView: ReadEventV3Query;
  refetch: () => void;
}> = (props) => {
  // media query to see if we are on a phone
  const isMobileSize = useMediaQuery("(max-width:767px)");
  return (
    <>
      {isMobileSize && (
        <>
          <TextGuestSummary
            yesRSVPs={
              props.eventView.getGlowEvent.reservationDetails?.yesReservation ??
              []
            }
            noRSVPS={
              props.eventView.getGlowEvent.reservationDetails?.noReservation ??
              []
            }
            maybeRSVPs={
              props.eventView.getGlowEvent.reservationDetails
                ?.maybeReservation ?? []
            }
            waitlistRSVPs={
              props.eventView.getGlowEvent.reservationDetails
                ?.waitlistReservation ?? []
            }
          />
        </>
      )}
      <GuestEditV2
        onClose={() => {
          props.refetch();
        }}
        eventId={props.eventView.getGlowEvent.eventCode}
      />
    </>
  );
};

const EditEvent2Host: React.FC<{
  eventView: ReadEventV3Query;
  refetch: () => void;
  setPreviewTheme: (themeId?: string) => void;
}> = (props) => {
  return (
    <>
      <EventPatchBox
        onSaved={() => {
          console.log("onSaved - refetch");
          props.refetch();
        }}
        eventCode={props.eventView.getGlowEvent.eventCode}
        initialName={props.eventView.getGlowEvent.name}
        initialDateTime={moment(props.eventView.getGlowEvent.isoTime)}
        initialLocationName={props.eventView.getGlowEvent.eventLocation}
        initialLocationMapUrl={
          props.eventView.getGlowEvent.eventLocationMapUrl ?? undefined
        }
        initialLocationAddress={
          props.eventView.getGlowEvent.locationAddress ?? undefined
        }
        initialDescription={props.eventView.getGlowEvent.description}
        initialTimeZone={
          props.eventView.getGlowEvent.timeZone ?? moment.tz.guess()
        }
      />

      <EditEventAdvancedBox
        eventCode={props.eventView.getGlowEvent.eventCode}
        refetchParent={props.refetch}
        setPreviewTheme={props.setPreviewTheme}
      />
    </>
  );
};

export const ViewEvent2Host: React.FC<{
  eventView: ReadEventV3Query;
  refetch: () => void;
  setPreviewTheme: (themeId?: string) => void;
}> = (props) => {
  const [selectedNav, internalSetSelectedNav] =
    useState<EventViewNav>("Preview");
  const [previousNav, setPreviousNav] = useState<EventViewNav | undefined>(
    undefined
  );
  const setSelectedNav = (nav: EventViewNav) => {
    setPreviousNav(selectedNav);
    internalSetSelectedNav(nav);
  };
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const containerRef = React.useRef<HTMLElement>(null);

  const animatedBoxSx: SxProps<Theme> = {
    width: "calc(100% - 20px)",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    position: "absolute",
    top: "50px",
    alignItems: "center",
    paddingBottom: "100px",
  };
  return (
    <>
      <GlowChrome
        navigationFAB={true}
        animation={true}
        shareAction={() => {
          setShareDialogOpen(true);
        }}
      />

      <>
        {/**Dialogs */}
        <ShareEventDialog
          eventCode={props.eventView.getGlowEvent.eventCode}
          eventTitle={props.eventView.getGlowEvent.name ?? ""}
          isoDate={moment(props.eventView.getGlowEvent.isoTime)}
          locationName={props.eventView.getGlowEvent.eventLocation ?? ""}
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
        />
      </>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          maxWidth: "520px",
          margin: "0px auto",
          padding: "10px 10px",
          position: "relative",
        }}
        ref={containerRef}
      >
        <NavBox active={selectedNav} setActive={setSelectedNav} />

        <Slide
          in={selectedNav === "Preview"}
          direction={previousNav === undefined ? "up" : "right"}
          container={containerRef.current}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={animatedBoxSx}>
            <InfoBox message="Below is a preview of what recipients will see" />

            <ViewEvent2Guest
              eventView={props.eventView}
              refetch={props.refetch}
            />
          </Box>
        </Slide>

        <Slide
          in={selectedNav === "Edit"}
          direction={
            previousNav === undefined
              ? "up"
              : previousNav === "Preview"
              ? "left"
              : "right"
          }
          container={containerRef.current}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={animatedBoxSx}>
            <EditEvent2Host
              eventView={props.eventView}
              refetch={props.refetch}
              setPreviewTheme={props.setPreviewTheme}
            />
          </Box>
        </Slide>

        <Slide
          in={selectedNav === "Guest Details"}
          direction={previousNav === undefined ? "up" : "left"}
          container={containerRef.current}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={animatedBoxSx}>
            <EditGuestView2
              eventView={props.eventView}
              refetch={props.refetch}
            />
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export const ViewEventWrapper2 = () => {
  const { shortcode } = useParams();

  const { data, loading, error, refetch } = useQuery(READ_EVENT_QUERY_V3, {
    variables: {
      eventCode: shortcode!,
    },
  });

  const userThemes = useUserThemes();
  const [previewTheme, setPreviewTheme] = useState<string | undefined>();

  if (loading || userThemes.isLoading) {
    return <div>Loading...</div>;
  }

  if (data?.getGlowEvent) {
    const themeOptions : ThemeOptions = JSON.parse(JSON.stringify(GetGlowLightThemeCopy()));
    var selectedTheme = userThemes.userThemes.find(
      (x) => x.id == data.getGlowEvent.themeId
    )!;

    if (previewTheme) {
      var previewThemeItem = userThemes.userThemes.find(
        (x) => x.id == previewTheme
      );
      selectedTheme = previewThemeItem ?? selectedTheme;
    }

    if (selectedTheme) {
      themeOptions.palette!.mode = "light";
      themeOptions.components!.MuiCssBaseline = {
        styleOverrides: {
          body: {
            backgroundColor: "#222222",
          },
        },
      };

      if (selectedTheme.primaryColor) {
        themeOptions.palette!.primary = {
          main: selectedTheme.primaryColor,
          contrastText: "#FFFFFF",
        };
      }

      if (selectedTheme.secondaryColor) {
        themeOptions.palette!.secondary = {
          main: selectedTheme.secondaryColor,
        };
      }

      if (selectedTheme.extraPaperColor) {
        themeOptions.palette!.extraPaperColor = {
          main: selectedTheme.extraPaperColor,
        };
      }
      if(selectedTheme.particles) {
        themeOptions.palette!.particles = selectedTheme.particles
      }

      if (selectedTheme.background) {
        var backgroundSize: string | undefined = undefined;
        var keyframes: object = {};
        var animation: string | undefined = undefined;
        var backgroundImg: string | undefined = undefined;

        if (selectedTheme.background.mode == "Fixed") {
          backgroundSize = "cover";
        }

        if (selectedTheme.background.mode == "Repeat") {
          backgroundSize = "repeat";
        }

        if (selectedTheme.background.mode == "Wander") {
          backgroundSize = "cover";
          keyframes = {
            backgroundRepeat: "no-repeat",
            "@keyframes wander": {
              "0%": {
                backgroundPosition: "0% 100%",
              },
              "50%": {
                backgroundPosition: "100% 0%",
              },
              "100%": {
                backgroundPosition: "0% 100%",
              },
            },
          };
          animation = "wander 15s ease infinite";
        }




        if (selectedTheme.background.svg) {
          const encodedSVG = encodeURIComponent(selectedTheme.background.svg);
          const svgDataUrl = `url('data:image/svg+xml;utf8,${encodedSVG}')`;
          backgroundImg = svgDataUrl;
        }

        if (selectedTheme.background.imageUrl) {
          const url = selectedTheme.background.imageUrl;
          if (url != undefined) {
            backgroundImg = `url('${url}')`;
            if (selectedTheme.background.mode == "Wander") {
              backgroundSize = "200% 200%";
            }
          }
        }

        if (selectedTheme.background.bgImageCss) {
          backgroundImg = selectedTheme.background.bgImageCss;
        }

        themeOptions.components!.MuiCssBaseline = {
          styleOverrides: {
            body: {
              backgroundImage: backgroundImg,
              backgroundSize: backgroundSize,
              animation: animation,
              ...keyframes,
            },
          },
        };
      }
      themeOptions.components!.MuiButton = {
        variants: [
          {
            props: { variant: "contained", color: "primary" },
            style: {
              borderRadius: "100px",
            },
          },
        ],
      };

      const secondaryColor = themeOptions.palette!.secondary! as SimplePaletteColorOptions;
      themeOptions.components!.MuiFab = {
        styleOverrides: {
          root: {
            color: "white",
            background: `linear-gradient(29.22deg, ${secondaryColor.main} 22.08%, rgba(187, 187, 187, 1) 121.28%)`,
            borderStyle: "solid",
            borderWidth: "2px",
            borderColor: secondaryColor.main,
          },
        },
      };
    }

    const theme = createTheme(themeOptions);

    if (data?.getGlowEvent.editable) {
      return (
        <ThemeProvider theme={theme}>
          <ViewEvent2Host
            eventView={data}
            refetch={refetch}
            setPreviewTheme={setPreviewTheme}
          />
        </ThemeProvider>
      );
    }

    if (data && !data?.getGlowEvent.editable) {
      return (
        <ThemeProvider theme={theme}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            maxWidth: "500px",
            padding: "10px 10px",            
          
          }}>
            <CssBaseline/>
            <ViewEvent2Guest eventView={data} refetch={refetch} />;
          </Box>
        </ThemeProvider>
      );
    }
  }

  return (
    <GlowColumnBox>
      <ErrorBox
        errorMessages={[
          "We can't find the event you are looking for.",
          "The event creator may have sent you the wrong link.",
        ]}
      />
    </GlowColumnBox>
  );
};

export default ViewEventWrapper;
