import { gql } from "../../codegen_cms";
import { UserTheme } from "../../codegen_cms/graphql";
import { useSanityQuery } from "../useSanityQuery";

export const GET_USER_THEMES = gql(`
  query AllThemes {
  allUserTheme (where: {
    _: {
      is_draft: false
    }
  }) {
    _id
    primaryColor{
      hex
      alpha
    }
    extraPaperColor {
      hex
      alpha
    }
    secondaryColor {
      hex
      alpha
    }
    name
    sortOrder
    userThemeBackground {
      bgImageCss
      svg
      image {
        asset {
          url
        }
      }
      mode
    }
    userThemeBackgroundDesktop {
      bgImageCss
      svg
      image {
        asset {
          url
        }
      }
      mode
    }
    menuIcon
    {
      asset
      {
        url
      }
    }
    particleJson
  }
}`);

export interface UserThemeSummary {
  id: string;
  primaryColor: string;
  secondaryColor: string;
  extraPaperColor: string;
  name: string;
  menuIcon: string;
  sortOrder: number;
  background: {
    bgImageCss?: string;
    svg?: string;
    imageUrl?: string;
    mode: string;
  };
  particles: string
}

export const useUserThemes = () => {
  const queryResult = useSanityQuery(GET_USER_THEMES, {
    allUserTheme: [],
  });

  const alphaToHex = (alpha?: number) => {
    if (alpha === undefined) {
      return "FF";
    }
    const hex = Math.round(alpha * 255)
      .toString(16)
      .toUpperCase();
    return hex.length === 1 ? "0" + hex : hex;
  };

  const mapToSummary = (x: UserTheme): UserThemeSummary => {
    return {
      id: x._id!,
      primaryColor:
        x.primaryColor?.hex ??
        "#FF0000" + alphaToHex(x.primaryColor?.alpha ?? undefined),
      secondaryColor:
        x.secondaryColor?.hex ??
        "#FF0000" + alphaToHex(x.secondaryColor?.alpha ?? undefined),
      extraPaperColor:
        x.extraPaperColor?.hex ??
        "#FF0000" + alphaToHex(x.extraPaperColor?.alpha ?? undefined),
      name: x.name ?? "Unknown",
      sortOrder: x.sortOrder ?? 1000000,
      menuIcon: x.menuIcon?.asset?.url ?? "https://via.placeholder.com/150",
      background: {
        bgImageCss: x.userThemeBackground?.bgImageCss ?? undefined,
        svg: x.userThemeBackground?.svg ?? undefined,
        imageUrl: x.userThemeBackground?.image?.asset?.url ?? undefined,
        mode: x.userThemeBackground?.mode ?? "Fixed",
      },
      particles: x.particleJson ?? "",
    };
  };

  const userThemes = queryResult.data?.allUserTheme.map(mapToSummary) ?? [];
  const defaultThemes = queryResult.defaultData.allUserTheme.map(mapToSummary);

  const sortedThemes = userThemes.sort((a, b) => a.sortOrder - b.sortOrder);

  return {
    userThemes: sortedThemes,
    defaultThemes,
    isLoading: queryResult.loading,
  };
};
