import {
  Box,
  Button,
  IconButton,
  Typography,
  Menu,
  useMediaQuery,
  useTheme,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { GlowColumnBox, GlowRowBox } from "./GlowBox";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ThemeExtensions } from "../themes/ThemeExtensions";
import { GlowSecondaryPaper } from "./GlowSecondaryPaper";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import moment from "moment";
import React, { useState } from "react";
import { GlowLocationLink } from "./GlowLocationDisplay";
import EventIcon from "@mui/icons-material/Event";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import { OutlookLogo } from "../components/Logo";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import { atcb_action } from "add-to-calendar-button";

interface EventDetailsProps {
  title: string;
  date: moment.Moment;
  timeZone: string;
  locationName: string;
  locationMapUrl?: string;
  locationAddress?: string;
  description: string;
  showEdit?: boolean;
  editClicked?: () => void;
  canceled?: boolean;
}
export const EventDetails: React.FC<EventDetailsProps> = (props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery("(max-width:767px)");
  const otherFontColor = ThemeExtensions(theme).text.accent;

  const localTimeOffset = props.date.clone().tz(moment.tz.guess()).format("z");
  const selectedTimeOffset = props.date.clone().tz(props.timeZone).format("z");
  const timeOffsetDiffers = localTimeOffset != selectedTimeOffset;

  return (
    <GlowSecondaryPaper
      variant="elevation"
      sx={{
        position: "relative",
        width: "90vw",
        maxWidth: "500px",
      }}
    >
      {props.showEdit && (
        <Button
          onClick={props.editClicked}
          variant="text"
          endIcon={<EditRoundedIcon />}
          sx={{
            position: "absolute",
            float: "right",
            margin: "5px",
            right: "10px",
          }}
        >
          <Box sx={{ lineHeight: "0px", marginTop: "5px" }}>Edit</Box>
        </Button>
      )}
      <GlowColumnBox
        sx={{
          padding: "16px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textDecoration: props.canceled ? "line-through" : undefined,
          }}
        >
          {props.title}
        </Typography>
        {props.canceled && (
          <Typography variant="subtitle2" color={theme.palette.error.main}>
            This event has been canceled by the host
          </Typography>
        )}
        <GlowColumnBox
          sx={{
            width: "100%",
            alignItems: "left",
          }}
        >
          <GlowColumnBox
            sx={{
              margin: "0px",
              padding: "0px",
              width: "100%",
              alignItems: "left",
              gap: "5px",
            }}
          >
            <Typography variant="h6">
              {props.date.format("dddd, MMMM D")}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "16px",
                color: otherFontColor,
              }}
            >
              {props.date
                .clone()
                .tz(props.timeZone)
                .format(timeOffsetDiffers ? "h:mm a (z)" : "h:mm a")}
            </Typography>
            {timeOffsetDiffers && (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  color: otherFontColor,
                }}
              >
                {props.date.clone().tz(moment.tz.guess()).format("h:mm a (z)")}
              </Typography>
            )}
          </GlowColumnBox>

          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
            }}
          >
            <GlowLocationLink
              locationName={props.locationName}
              locationMapUrl={props.locationMapUrl}
              locationAddress={props.locationAddress}
            />
          </Typography>
          <Typography variant="body2">{props.description}</Typography>
        </GlowColumnBox>
      </GlowColumnBox>
    </GlowSecondaryPaper>
  );
};

interface EventDetailsV2Props {
  eventId: string;
  title: string;
  date: moment.Moment;
  timeZone: string;
  locationName: string;
  locationMapUrl?: string;
  locationAddress?: string;
  description: string;
  showEdit?: boolean;

  canceled?: boolean;
}
export const EventDetailsV2: React.FC<EventDetailsV2Props> = (props) => {
  const isMobileSize = useMediaQuery("(max-width:767px)");

  const localTimeOffset = props.date.clone().tz(moment.tz.guess()).format("z");
  const selectedTimeOffset = props.date.clone().tz(props.timeZone).format("z");
  const timeOffsetDiffers = localTimeOffset != selectedTimeOffset;

  const [calendarMenuAnchorElement, setCalendarMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  return (
    <GlowSecondaryPaper
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          style={{ fontWeight: "bold" }}
          sx={{
            textDecoration: props.canceled ? "line-through" : undefined,
          }}
        >
          {props.title}
        </Typography>
        {props.canceled && (
          <Typography variant="subtitle2" color={(t) => t.palette.error.main}>
            This event has been canceled by the host
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Menu
            open={Boolean(calendarMenuAnchorElement)}
            anchorEl={calendarMenuAnchorElement}
            onClose={() => setCalendarMenuAnchorElement(null)}
          >
            {["Apple", "Google", "Outlook"].map((x, index) => {
              const iconMap = {
                Apple: <AppleIcon />,
                Google: <GoogleIcon />,
                iCal: <EventRoundedIcon />,
                Outlook: <OutlookLogo />,
              };
              const actionMap = {
                Apple: "Apple",
                Google: "Google",
                iCal: "iCal",
                Outlook: "Outlook.com",
              }
              const icon = (iconMap as any)[x];
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    const start = moment(props.date.toISOString());
                    const end = moment(props.date.toISOString()).add(1, "hour");

                    const calendarName = `${
                      props.title ?? "Invitation"
                    }: (from www.glow.rsvp)`;
                    var host = window.location.host;
                    if (host.startsWith("www.")) {
                      host = host.substring(4);
                    }
                    const eventLink = `${window.location.protocol}//${host}/${props.eventId}`;
                    const description = `[strong]${props.title}[/strong]
[br]When: ${props.date.format("dddd, MMMM D @ h:mm a")}
[br]Where: ${props.locationName}${
                      props.locationAddress ? ", " + props.locationAddress : ""
                    }[br]
[br][p]${
                      props.description
                    }[br][br]See all details at [url]${eventLink}[/url][/p]`;

                    atcb_action({
                      name: calendarName,
                      startDate: start.format("YYYY-MM-DD"),
                      startTime: start.format("HH:mm"),
                      endDate: end.format("YYYY-MM-DD"),
                      endTime: end.format("HH:mm"),
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      location:
                        x == "Google" && props.locationAddress
                          ? props.locationName + " " + props.locationAddress
                          : props.locationMapUrl ?? props.locationName ?? "",
                      options: [(actionMap as any)[x] as any],
                      description: description,
                    });

                    setCalendarMenuAnchorElement(null);
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{x}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
          <IconButton
            onClick={(x) => setCalendarMenuAnchorElement(x.currentTarget)}
            sx={{
              borderColor: (t) => t.palette.primary.main,
              border: "1px solid",
              color: (t) => t.palette.primary.main,
              width: "40px",
              height: "40px",
            }}
          >
            <EventIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              margin: "0px",
              padding: "0px",
              alignItems: "left",
            }}
          >
            <Typography variant="h6">
              {props.date.format("dddd, MMMM D")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                justifyContent: "left",
                alignItems: "end",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              >
                {props.date
                  .clone()
                  .tz(props.timeZone)
                  .format(timeOffsetDiffers ? "h:mm a (z)" : "h:mm a")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              >
                {timeOffsetDiffers && (
                  <>
                    {props.date
                      .clone()
                      .tz(moment.tz.guess())
                      .format("h:mm a (z)")}
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        {props.locationName && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                if (isMobileSize) {
                  window.location.href = props.locationMapUrl!;
                } else {
                  window.open(props.locationMapUrl!, "_blank");
                }
              }}
              sx={{
                visibility: props.locationMapUrl ? "visible" : "hidden",
                borderColor: (t) => t.palette.primary.main,
                border: "1px solid",
                color: (t) => t.palette.primary.main,
                width: "40px",
                height: "40px",
              }}
            >
              <LocationOnIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: "1",
                margin: "0px",
                padding: "0px",
                alignItems: "left",
              }}
            >
              <Typography variant="h6">{props.locationName}</Typography>
              {props.locationAddress && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: (t) => t.palette.primary.main,
                    }}
                  >
                    {props.locationAddress?.split(",")[0]}
                  </Typography>
                  {props.locationAddress?.split(",").length > 2 && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: (t) => t.palette.primary.main,
                      }}
                    >
                      {props.locationAddress?.split(",")[1]},
                      {props.locationAddress?.split(",")[2]}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
        <Typography variant="body2">{props.description}</Typography>
      </Box>
    </GlowSecondaryPaper>
  );
};
