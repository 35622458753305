import { Box, Divider, Typography } from "@mui/material";
import { GlowColumnBox, GlowRowBox } from "./GlowBox";
import {
  GoingIcon,
  HostIcon,
  NotGoingIcon,
  UnsureIcon,
  WaitlistIcon,
} from "./ResponseIcons";
import { ReservationStatus } from "../../codegen/graphql";
import { GlowSecondaryPaper } from "./GlowSecondaryPaper";
import { InfoBox } from "./InfoBox";

interface RSVPViewV2Reservation {
  name: string;
  numerOfGuests: number;
  currentUser: boolean;
}

interface RSVPViewV2Props {
  host: string[];
  isHost: boolean;
  capacity?: number;
  attending: number;
  yesCount: number;
  yesReservations: RSVPViewV2Reservation[];
  maybeCount: number;
  maybeReservations: RSVPViewV2Reservation[];
  noCount: number;
  noReservations: RSVPViewV2Reservation[];
  waitlistCount: number;
  waitlistReservations: RSVPViewV2Reservation[];
  options: {
    showAccepts: boolean;
    showMaybes: boolean;
    showDeclines: boolean;
    showWaitlist: boolean;
  };
}

export const RSVPViewV2: React.FC<RSVPViewV2Props> = (props) => {
  const responseRenderMap = (
    r: RSVPViewV2Reservation,
    ndx: number,
    array: RSVPViewV2Reservation[]
  ): JSX.Element => (
    <>
      <Typography>
        {r.name}
        <Box
          component="span"
          color={(t) =>
            t.palette.mode == "dark"
              ? t.palette.primary.main
              : t.palette.primary.dark
          }
        >
          {r.numerOfGuests > 1 && <>&nbsp;{`(+${r.numerOfGuests - 1})`}</>}
        </Box>
        {ndx < array.length - 1 && <>,&nbsp;</>}
      </Typography>
    </>
  );

  const responseRender = (
    showAll: boolean,
    arr: RSVPViewV2Reservation[],
    iconElement: JSX.Element
  ) => {
    const arrToRender = arr.filter((x) => showAll || x.currentUser);
    if (arrToRender.length == 0) {
      return <></>;
    }
    return (
      <GlowRowBox sx={{ width: "100%", justifyContent: "left", margin: "0" }}>
        {iconElement}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {arrToRender
            .filter((x) => showAll || x.currentUser)
            .map(responseRenderMap)}
        </Box>
      </GlowRowBox>
    );
  };

  var headerString = "";
  if (props.options.showAccepts && props.yesCount > 0) {
    headerString += `${props.yesCount} YES`;
  }

  if (props.options.showMaybes && props.maybeCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${props.maybeCount} MAYBE`;
  }

  if (props.options.showDeclines && props.noCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${props.noCount} NO`;
  }

  if (props.options.showAccepts && props.capacity) {
    if (props.isHost && props.waitlistCount > 0) {
      if (headerString.length > 0) headerString += " | ";

      headerString += `${props.waitlistCount} WAITING`;
    } else if (props.attending < props.capacity) {
      if (headerString.length > 0) headerString += " | ";
      var remaining = props.capacity - props.attending;

      if (props.waitlistCount > 0) {
        remaining = 0;
      }
      headerString += `${remaining} SPOT${remaining > 1 ? "S" : ""} LEFT`;
    }
  }

  var overage = 0;
  if (props.capacity && props.yesCount > props.capacity) {
    overage = props.yesCount - props.capacity;
  }

  return (
    <GlowColumnBox sx={{ width: "100%", gap: "18px", maxWidth: "500px" }}>
      <Box sx={{ width: "100%", margin: "0px" }}>
        {props.isHost ||
        props.options.showAccepts ||
        props.options.showDeclines ||
        props.options.showMaybes ? (
          <>
            <Typography>{headerString}</Typography>
            {props.isHost && overage > 0 && (
              <Typography
                variant="body2"
                color="error"
              >{`OVER LIMIT BY ${overage}`}</Typography>
            )}
          </>
        ) : (
          <Typography>Host has not shared guest list</Typography>
        )}
      </Box>
      <GlowRowBox sx={{ width: "100%", justifyContent: "left", margin: "0" }}>
        <HostIcon />
        <Typography>Hosted by: {props.host.join(", ")}</Typography>
      </GlowRowBox>
      {responseRender(
        props.options.showAccepts,
        props.yesReservations,
        <GoingIcon />
      )}
      {responseRender(
        props.options.showWaitlist,
        props.waitlistReservations,
        <WaitlistIcon />
      )}
      {responseRender(
        props.options.showMaybes,
        props.maybeReservations,
        <UnsureIcon />
      )}
      {responseRender(
        props.options.showDeclines,
        props.noReservations,
        <NotGoingIcon />
      )}
    </GlowColumnBox>
  );
};

export const RSVPViewV3: React.FC<RSVPViewV2Props> = (props) => {
  const responseRenderMap = (
    r: RSVPViewV2Reservation,
    ndx: number,
    array: RSVPViewV2Reservation[]
  ): JSX.Element => (
    <>
      <Typography>
        {r.name}
        <Box
          component="span"
          color={(t) =>
            t.palette.mode == "dark"
              ? t.palette.primary.main
              : t.palette.primary.dark
          }
        >
          {r.numerOfGuests > 1 && <>&nbsp;{`(+${r.numerOfGuests - 1})`}</>}
        </Box>
        {ndx < array.length - 1 && <>,&nbsp;</>}
      </Typography>
    </>
  );

  const responseRender = (
    showAll: boolean,
    arr: RSVPViewV2Reservation[],
    iconElement: JSX.Element
  ) => {
    const arrToRender = arr.filter((x) => showAll || x.currentUser);
    if (arrToRender.length == 0) {
      return <></>;
    }
    return (
      <GlowRowBox sx={{ width: "100%", justifyContent: "left", margin: "0" }}>
        {iconElement}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {arrToRender
            .filter((x) => showAll || x.currentUser)
            .map(responseRenderMap)}
        </Box>
      </GlowRowBox>
    );
  };

  var headerString = "";
  if (props.options.showAccepts && props.yesCount > 0) {
    headerString += `${props.yesCount} YES`;
  }

  if (props.options.showMaybes && props.maybeCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${props.maybeCount} MAYBE`;
  }

  if (props.options.showDeclines && props.noCount > 0) {
    if (headerString.length > 0) headerString += " | ";
    headerString += `${props.noCount} NO`;
  }

  if (props.options.showAccepts && props.capacity) {
    if (props.isHost && props.waitlistCount > 0) {
      if (headerString.length > 0) headerString += " | ";

      headerString += `${props.waitlistCount} WAITING`;
    } else if (props.attending < props.capacity) {
      if (headerString.length > 0) headerString += " | ";
      var remaining = props.capacity - props.attending;

      if (props.waitlistCount > 0) {
        remaining = 0;
      }
      headerString += `${remaining} SPOT${remaining > 1 ? "S" : ""} LEFT`;
    }
  }

  var overage = 0;
  if (props.capacity && props.yesCount > props.capacity) {
    overage = props.yesCount - props.capacity;
  }

  return (
    <GlowSecondaryPaper sx={{ width: "100%", gap: "18px", maxWidth: "500px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "18px",
          gap: "18px",
          maxWidth: "500px",
        }}
      >
        <Box sx={{ width: "100%", margin: "0px" }}>
          {props.isHost ||
          props.options.showAccepts ||
          props.options.showDeclines ||
          props.options.showMaybes ? (
            <>
              <Typography>{headerString}</Typography>
              {props.isHost && overage > 0 && (
                <Typography
                  variant="body2"
                  color="error"
                >{`OVER LIMIT BY ${overage}`}</Typography>
              )}
            </>
          ) : (
            <Typography>Host has not shared guest list</Typography>
          )}
        </Box>
        <GlowRowBox sx={{ width: "100%", justifyContent: "left", margin: "0" }}>
          <HostIcon />
          <Typography>Hosted by: {props.host.join(", ")}</Typography>
        </GlowRowBox>
        {responseRender(
          props.options.showAccepts,
          props.yesReservations,
          <GoingIcon />
        )}
        {responseRender(
          props.options.showWaitlist,
          props.waitlistReservations,
          <WaitlistIcon />
        )}
        {responseRender(
          props.options.showMaybes,
          props.maybeReservations,
          <UnsureIcon />
        )}
        {responseRender(
          props.options.showDeclines,
          props.noReservations,
          <NotGoingIcon />
        )}
      </Box>
    </GlowSecondaryPaper>
  );
};
